import _ from 'lodash'

// Combined reusable function
export const processAndJoinFields = <T>(
  array: T[],
  key: keyof T,
  field?: keyof T[keyof T],
  separator = ', ',
  fallback = '-',
  unique = true
) => {
  // Step 1: Extract the values from the array
  const values = array?.reduce((acc: string[], curr: T) => {
    const value = field ? curr?.[key]?.[field] : curr?.[key]
    return value ? [...acc, String(value)] : acc
  }, [])

  // Step 2: Handle uniqueness if needed
  const processedValues = unique ? _.uniq(values) : values

  // Step 3: Join the values or return fallback
  return processedValues.length > 0 ? processedValues.join(separator) : fallback
}
