import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import dayjs from 'dayjs'
import { isEqual, isUndefined, toLower, toString } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import cmndBack from 'src/_metronic/assets/media/auth/cmndmatsau.png'
import cmndFront from 'src/_metronic/assets/media/auth/cmndmattruoc.png'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import { updateStudent, uploadFile, UsersAPI } from 'src/apis/user'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import HeaderTab from 'src/components/base/HeaderTab'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextArea from 'src/components/base/textaria/HookFormTextArea'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import ModalEditEmail from 'src/components/user-list/components/user-edit-modal/ModalEditEmail'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  ACCOUNT_TYPE,
  BREAKPOINT,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_HUBSPOT_CONTACT_ID,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  IS_STAFF,
  MESSAGE_VALIDATION_USERNAME,
  STATUS_FORM,
  STUDENT_PROFILE,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MAX_HUBSPOT_CONTACT_ID,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_HUBSPOT_CONTACT_ID,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PHONE,
  VALIDATION_FILED,
} from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM, LANG_PLACEHOLDER, LANG_USER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import useWindowWidth from 'src/hooks/use-width'
import { EEntranceLevel, IError, Role } from 'src/type'
import { CourseNameLevel } from 'src/type/courses'
import { IStudentDetail, IStudents, IUniveristy } from 'src/type/students'
import { phoneRegExp, sizeInBytes, usernamePattern } from 'src/utils'
import { z } from 'zod'

const { Option } = Select

interface IProps {
  studentDetail: IStudentDetail | undefined
  setStudentDetail: Dispatch<SetStateAction<any>>
}

const EditStudentProfile = ({ studentDetail, setStudentDetail }: IProps) => {
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [showFrontDefault, setShowFrontDefault] = useState(false)
  const [showBackDefault, setShowBackDefault] = useState(false)
  const [universities, setUniversities] = useState<IUniveristy[]>([])
  const { confirm, contextHolder } = useConfirm()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const { profileMe } = useUserContext()
  const [acountType, setAccountType] = useState(studentDetail?.type_user)
  const typeEmployee = acountType === 'EMPLOYEE'

  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.PUT_CHANGE_EMAIL_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${STUDENT_PROFILE}/${id}/overview`),
    })
  }

  const getUniversities = async () => {
    try {
      const res = await UsersAPI.universities()
      setUniversities(res?.data)
    } catch (error) {}
  }

  useEffect(() => {
    if (allowRenderEdit) {
      getUniversities()
    }
  }, [allowRenderEdit])
  const DEFAULT_SCHEMA = {
    full_name: z
      .string({ required_error: VALIDATION_FILED })
      .min(3, { message: VALIDATE_MIN_FULLNAME })
      .max(100, { message: VALIDATE_MAX_FULLNAME }),
    email: z.string({ required_error: VALIDATION_FILED }).email(),
    phone: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
    sex: z.string().optional(),
    address: z.string().optional(),
    major: z.string().optional(),
    university: z.string().optional(),
    dob: z.any(),
    note: z.string().max(512).optional(),
    level: z.string({ required_error: VALIDATION_FILED }).optional(),
    cma_level: z.string({ required_error: VALIDATION_FILED }).optional(),
    acca_level: z.string({ required_error: VALIDATION_FILED }).optional(),
    avatar: z.any(),
    id_front: z.any(),
    id_back: z.any(),
    status: z.string({ required_error: VALIDATION_FILED }).min(1),
    // examination_code: z.string().optional(),
    learning_purpose: z.string().optional(),
    contact_detail: z.string().optional(),
    special_note: z.string().optional(),
    classification: z.string().optional(),
    username: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(usernamePattern), {
        message: MESSAGE_VALIDATION_USERNAME,
      })
      .min(6, { message: VALIDATE_MIN_USERNAME })
      .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
    is_sapp_operator: z.string().optional(),
    type_user: z.string().min(1, { message: VALIDATION_FILED }),
  }

  const schemaCreate = z.object(
    Object.assign({}, DEFAULT_SCHEMA, {
      hubspot_contact_id: z
        .string({ required_error: VALIDATION_FILED })
        .min(3, { message: VALIDATE_MIN_HUBSPOT_CONTACT_ID })
        .max(20, { message: VALIDATE_MAX_HUBSPOT_CONTACT_ID }),
    })
  )

  const schemaCode = z.object(
    Object.assign({}, DEFAULT_SCHEMA, {
      employee_code: z
        .string({ required_error: VALIDATION_FILED })
        .min(3, { message: VALIDATE_MIN_HUBSPOT_CONTACT_ID })
        .max(20, { message: VALIDATE_MAX_HUBSPOT_CONTACT_ID }),
    })
  )

  const validation = useMemo(
    () => (typeEmployee ? schemaCode : schemaCreate),
    [typeEmployee, schemaCreate, schemaCode]
  )

  const { handleSubmit, control, setValue, setError } = useForm<IStudents>({
    resolver: zodResolver(validation),
    mode: 'onSubmit',
  })

  // submit form
  const onSubmit = async (data: IStudents) => {
    const {
      address,
      dob,
      email,
      level,
      cma_level,
      acca_level,
      full_name,
      hubspot_contact_id,
      major,
      note,
      phone,
      sex,
      university,
      username,
      status,
      avatar,
      id_back,
      id_front,
      // examination_code,
      classification,
      contact_detail,
      learning_purpose,
      special_note,
      is_sapp_operator,
      type_user,
    } = data

    // Prevent form submission
    if (!isUndefined(avatar) && (avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE))
      return
    if (!isUndefined(id_front) && (id_front?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE))
      return
    if (!isUndefined(id_back) && (id_back?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE))
      return

    try {
      setLoadingSubmit(true)
      if (!isUndefined(avatar) || !isUndefined(id_front) || !isUndefined(id_back)) {
        await uploadFile({
          userId: studentDetail?.id,
          avatar: avatar,
          id_front: id_front,
          id_back: id_back,
        })
      }

      const paramsUpdate: any = {
        username: username,
        email: email.toLowerCase(),
        phone: phone,
        dob: dayjs(dob).add(1, 'day'),
        sex: sex || null,
        address: address,
        major: major,
        university: university,
        note: note,
        level: level || null,
        cma_level: cma_level || null,
        acca_level: acca_level || null,
        full_name: full_name,
        hubspot_contact_id: hubspot_contact_id || undefined,
        employee_code: data?.employee_code || undefined,
        status: status,
        // examination_code: examination_code,
        classification: classification,
        contact_detail: contact_detail,
        learning_purpose: learning_purpose,
        special_note: special_note,
        avatar: null,
        identity_card_image_front: null,
        identity_card_image_back: null,
        is_sapp_operator: isEqual(toLower(is_sapp_operator as string), 'true'),
        type_user: type_user,
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      if (!isUndefined(id_front) || !showFrontDefault) {
        delete paramsUpdate.identity_card_image_front
      }

      if (!isUndefined(id_back) || !showBackDefault) {
        delete paramsUpdate.identity_card_image_back
      }

      await updateStudent({
        id: studentDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update Successfully!')
          navigate(`${STUDENT_PROFILE}/${id}/overview`)
        })
        .catch((err: any) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, { message: errorMessage })
          }, {})
        })
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IError) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
      setLoadingSubmit(false)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const userContact = studentDetail?.user_contacts?.find((user) => user.is_default)

  useEffect(() => {
    if (!id) return

    setValue('username', studentDetail?.username ?? '')
    setValue('full_name', studentDetail?.detail?.full_name ?? '')

    setValue('email', userContact?.email ?? '')
    setValue('status', studentDetail?.status ?? '')
    setValue('phone', userContact?.phone ?? '')
    setValue('major', studentDetail?.detail?.major ?? '')
    setValue('university', studentDetail?.detail?.university ?? '')
    setValue('sex', studentDetail?.detail?.sex ?? '')
    setValue('level', studentDetail?.detail?.level ?? '')
    setValue('cma_level', studentDetail?.detail?.cma_level ?? '')
    setValue('acca_level', studentDetail?.detail?.acca_level ?? '')
    setValue('address', studentDetail?.detail?.address ?? '')
    setValue('dob', studentDetail?.detail?.dob ?? '')
    // setValue('examination_code', studentDetail?.code ?? '')
    setValue('note', studentDetail?.detail?.note ?? '')

    setValue('learning_purpose', studentDetail?.detail?.learning_purpose ?? '')
    setValue('classification', studentDetail?.detail?.classification ?? '')
    setValue('contact_detail', studentDetail?.detail?.contact_detail ?? '')
    setValue('special_note', studentDetail?.detail?.special_note ?? '')
    setValue('is_sapp_operator', toString(studentDetail?.is_sapp_operator) ?? '')
    setValue('type_user', studentDetail?.type_user ?? '')
    setAccountType(studentDetail?.type_user)
    setValue('employee_code', studentDetail?.employee_code ?? '')
    setValue('hubspot_contact_id', studentDetail?.hubspot_contact_id ?? '')
  }, [
    id,
    setValue,
    // studentDetail?.code,
    studentDetail?.detail?.address,
    studentDetail?.detail?.classification,
    studentDetail?.detail?.contact_detail,
    studentDetail?.detail?.dob,
    userContact?.email,
    studentDetail?.detail?.full_name,
    studentDetail?.hubspot_contact_id,
    studentDetail?.detail?.learning_purpose,
    studentDetail?.detail?.level,
    studentDetail?.detail?.cma_level,
    studentDetail?.detail?.acca_level,
    studentDetail?.detail?.major,
    studentDetail?.detail?.note,
    userContact?.phone,
    studentDetail?.detail?.sex,
    studentDetail?.detail?.special_note,
    studentDetail?.detail?.university,
    studentDetail?.status,
    studentDetail?.username,
    studentDetail?.is_sapp_operator,
    studentDetail?.employee_code,
    studentDetail?.type_user,
    studentDetail,
  ])

  // const [duration, setDuration] = useState(0)
  const [isActive, setIsActive] = useState(false)
  // const {sendEmailOTPUser} = useUserContext()

  // const onSubmitSendOTP = () => {
  //   if (isActive) return

  //   sendEmailOTPUser(id).then(() => {
  //     setDuration(DURATION_EMAIL)

  //     setIsActive(true)
  //   })
  // }

  const widthWindow = useWindowWidth()
  let identity_card_front = null
  let identity_card_back = null

  if (widthWindow > BREAKPOINT.xs) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['280x180']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['280x180']
  }

  if (widthWindow > BREAKPOINT.md) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['320x190']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['320x190']
  }

  if (widthWindow > BREAKPOINT.xl) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['370x220']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['370x220']
  }

  const courseLevel = [
    {
      name: CourseNameLevel.LEVEL,
      label: 'CFA Level',
      defaultValue: studentDetail?.detail?.level,
      allowEdit: !studentDetail?.allow_edit_level,
    },
    {
      name: CourseNameLevel.CMA,
      label: 'CMA Level',
      defaultValue: studentDetail?.detail?.cma_level,
      allowEdit: !studentDetail?.allow_edit_cma_level,
    },
    {
      name: CourseNameLevel.ACCA,
      label: 'ACCA Level',
      defaultValue: studentDetail?.detail?.acca_level,
      allowEdit: !studentDetail?.allow_edit_acca_level,
    },
  ]

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <HeaderTab title='Profile Details' />

        <div className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <SappLabel label='Avatar' className='col-lg-3 col-form-label' />
                <div className='col-lg-9'>
                  <SAPPHookUploadFile
                    name='avatar'
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    imagePreview={
                      studentDetail?.detail?.avatar?.['150x150'] ??
                      studentDetail?.detail?.avatar?.ORIGIN ??
                      avatar
                    }
                    accept={ACCEPT_UPLOAD_MIME_TYPE}
                    maxFileSize={DEFAULT_MAX_FILE_SIZE}
                    setShowAvatarDefault={setShowAvatarDefault}
                    removeAvatar={avatar}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='CMND/CCCD' className='col-xl-3 col-lg-12 col-form-label' />
                <div className='col-xl-4 col-md-6 col-lg-12'>
                  <SAPPHookUploadFile
                    name='id_front'
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    imagePreview={identity_card_front ?? cmndFront}
                    accept={ACCEPT_UPLOAD_MIME_TYPE}
                    maxFileSize={DEFAULT_MAX_FILE_SIZE}
                    setShowFrontDefault={setShowFrontDefault}
                    removeAvatar={cmndFront}
                    width='sapp-w-front--face'
                    height='sapp-h-front--face'
                    disabled={!allowRenderEdit}
                  />
                </div>
                <div className='col-xl-4 col-md-6 col-lg-12'>
                  <SAPPHookUploadFile
                    name='id_back'
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    imagePreview={identity_card_back ?? cmndBack}
                    accept={ACCEPT_UPLOAD_MIME_TYPE}
                    maxFileSize={DEFAULT_MAX_FILE_SIZE}
                    setShowBackDefault={setShowBackDefault}
                    removeAvatar={cmndBack}
                    width='sapp-w-front--face'
                    height='sapp-h-front--face'
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-md-6 mb-0'>
                <SappLabel
                  label='Account Type'
                  className='col-xl-3 col-lg-3 col-form-label'
                  required
                />

                <div className='col-md-10 col-xl-9 col-lg-7 fv-row'>
                  <HookFormSelectAntd
                    name='type_user'
                    control={control}
                    required
                    onChange={(e: any) => {
                      setAccountType(e)
                      setValue('hubspot_contact_id', '')
                      setValue('employee_code', '')
                    }}
                  >
                    {ACCOUNT_TYPE.map((type) => (
                      <Option value={type.value} key={type.label}>
                        {type.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel
                  label={typeEmployee ? LANG_USER.EMPLOYEE_CODE : LANG_USER.STUDENT_ID}
                  className='col-lg-3 col-form-label'
                  required
                />
                <div className={`${typeEmployee ? 'col-lg-9 fv-row' : 'd-none'}`}>
                  <HookFormTextField
                    name='employee_code'
                    control={control}
                    defaultValue={studentDetail?.employee_code}
                    required
                    guideline={['Mã code tài khoản học viên của nhân sự SAPP']}
                    disabled={!allowRenderEdit}
                  />
                </div>
                <div className={`${!typeEmployee ? 'col-lg-9 fv-row' : 'd-none'}`}>
                  <HookFormTextField
                    name='hubspot_contact_id'
                    control={control}
                    defaultValue={studentDetail?.hubspot_contact_id}
                    required
                    guideline={GUIDELINE_HUBSPOT_CONTACT_ID}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-md-6 mb-0'>
                <SappLabel label='Email' className='col-xl-3 col-lg-3 col-form-label' required />

                <div
                  className={`${
                    studentDetail?.status === 'MERGED' ? 'col-lg-9' : 'col-md-10 col-xl-8 col-lg-7'
                  } fv-row`}
                >
                  <HookFormTextField
                    name='email'
                    control={control}
                    defaultValue={studentDetail?.detail?.email}
                    required
                    disabled
                    guideline={GUIDELINE_EMAIL}
                  />
                </div>
                {studentDetail?.status !== 'MERGED' && allowRender && (
                  <div
                    className='col-xl-1 col-lg-2 col-md-2 d-flex justify-content-end mt-md-0 mt-3'
                    onClick={() => setOpenEditEmail(true)}
                  >
                    <ButtonPrimary title='Edit' />
                  </div>
                )}
              </div>

              <div className='row mb-6'>
                <SappLabel label='Phone' className='col-lg-3 col-form-label' required />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='phone'
                    control={control}
                    defaultValue={studentDetail?.detail?.phone}
                    required
                    guideline={GUIDELINE_PHONE}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel
                  label={LANG_PLACEHOLDER.FULLNAME}
                  className='col-lg-3 col-form-label'
                  required
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='full_name'
                    control={control}
                    defaultValue={studentDetail?.detail?.full_name}
                    required
                    guideline={GUIDELINE_FULLNAME}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Username' className='col-lg-3 col-form-label' required />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='username'
                    control={control}
                    defaultValue={studentDetail?.username}
                    required
                    guideline={GUIDELINE_USERNAME}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Status' className='col-lg-3 col-form-label' required />
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    name='status'
                    control={control}
                    dropdownStyle={{ minWidth: 'fit-content' }}
                    placeholder='Status'
                    filterOption={true}
                    defaultValue={studentDetail?.status ?? STATUS_FORM[0].value}
                    required
                    disabled={!allowRenderEdit}
                    classNameHeight='sapp-h-45px'
                  >
                    {STATUS_FORM.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Major' className='col-lg-3 col-form-label' />
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    size='large'
                    name='major'
                    control={control}
                    dropdownStyle={{ minWidth: 'fit-content' }}
                    placeholder='Please select'
                    filterOption={true}
                    defaultValue={studentDetail?.detail?.major ?? ''}
                    disabled={!allowRenderEdit}
                  >
                    <Option value='Tài chính'>Tài chính</Option>
                    <Option value='Kế kiểm'>Kế kiểm</Option>
                    <Option value='Quản trị kinh doanh'>Quản trị kinh doanh</Option>
                    <Option value='Khác'>Khác</Option>
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='University' className='col-lg-3 col-form-label' />
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    classNameHeight='sapp-h-45px'
                    name='university'
                    control={control}
                    placeholder='Please select'
                    defaultValue={studentDetail?.detail?.university ?? universities?.[0]?.name}
                    disabled={!allowRenderEdit}
                  >
                    {universities?.map((university) => (
                      <Option key={university.code} value={university.name}>
                        {university.name}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Gender' className='col-lg-3 col-form-label' />

                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    classNameHeight='sapp-h-45px'
                    name='sex'
                    control={control}
                    dropdownStyle={{ minWidth: 'fit-content' }}
                    placeholder='Please select'
                    filterOption={true}
                    defaultValue={studentDetail?.detail?.sex ?? GENDER_FORM[0]?.value}
                    disabled={!allowRenderEdit}
                  >
                    {GENDER_FORM.map((gender) => (
                      <Option value={gender.value} key={gender.label}>
                        {gender.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Learning Purpose' className='col-lg-3 col-form-label' />

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    control={control}
                    name='learning_purpose'
                    placeholder='Learning Purpose'
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Contact Detail' className='col-lg-3 col-form-label' />

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField control={control} name='contact_detail' />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Special Note' className='col-lg-3 col-form-label' />

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField control={control} name='special_note' />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Classification' className='col-lg-3 col-form-label' />

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField control={control} name='classification' />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Dob' className='col-lg-3 col-form-label' />

                <div className='col-lg-9 fv-row'>
                  <HookFormDateTime
                    control={control}
                    name='dob'
                    defaultValue={studentDetail?.detail?.dob}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Note' className='col-lg-3 col-form-label' />

                <div className='col-lg-9 fv-row'>
                  <HookFormTextArea
                    control={control}
                    name='note'
                    placeholder='Note'
                    defaultValue={studentDetail?.detail?.note ?? ''}
                    rows={5}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-md-6 mb-0'>
                <SappLabel
                  label={LANG_USER.TEST_ACCOUNT}
                  className='col-xl-3 col-lg-3 col-form-label'
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    classNameHeight='sapp-h-45px'
                    name='is_sapp_operator'
                    control={control}
                    dropdownStyle={{ minWidth: 'fit-content' }}
                    placeholder='Please select'
                    disabled={(studentDetail?.is_sapp_operator as boolean) || !allowRenderEdit}
                  >
                    {IS_STAFF.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>

              {courseLevel.map((course) => {
                return (
                  <div className='row mb-6' key={course.label}>
                    <SappLabel label={course.label} className='col-lg-3 col-form-label' />
                    <div className='col-lg-9 fv-row'>
                      <HookFormSelectAntd
                        control={control}
                        name={course.name}
                        classNameHeight='sapp-h-45px'
                        defaultValue={course.defaultValue ?? EEntranceLevel.IA}
                        isLocked={course.allowEdit}
                        disabled={course.allowEdit || !allowRenderEdit}
                      >
                        <Option value={EEntranceLevel.IA}>{EEntranceLevel.IA}</Option>
                        <Option value={EEntranceLevel.IIA}>{EEntranceLevel.IIA}</Option>
                        <Option value={EEntranceLevel.IB}>{EEntranceLevel.IB}</Option>
                        <Option value={EEntranceLevel.IIB}>{EEntranceLevel.IIB}</Option>
                      </HookFormSelectAntd>
                    </div>
                  </div>
                )
              })}

              <div className='row mb-6'>
                <SappLabel label='Address' className='col-lg-3 col-form-label' />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    control={control}
                    name='address'
                    defaultValue={studentDetail?.detail?.address ?? ''}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-semibold fs-6'>Examination Code</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    control={control}
                    name='examination_code'
                    placeholder='Examination Code'
                    defaultValue={studentDetail?.code ?? ''}
                  />
                </div>
              </div> */}
            </div>
            {studentDetail?.status !== 'MERGED' && (
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <ButtonSecondary title='Discard' className='me-2' onClick={hanleCancel} />
                <ButtonPrimary
                  title='Save Changes'
                  onClick={handleSubmit(onSubmit)}
                  loading={loadingSubmit}
                  // disabled={!allowRenderEdit}
                />
              </div>
            )}
          </div>
        </div>

        <ModalEditEmail
          open={openEditEmail}
          setOpen={setOpenEditEmail}
          isActive={isActive}
          setIsActive={setIsActive}
          currentEmail={userContact?.email}
          setStudentDetail={setStudentDetail}
        />
      </div>
    </>
  )
}

export default EditStudentProfile
