import { useFieldArray, useForm } from 'react-hook-form'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import HookFormTextField from '../base/textfield/HookFormTextField'
import './CertificatesTemplate.scss'
import CertificatesTemplateIcons from './CertificatesTemplateIcons'
import { Select } from 'antd'
import HookFormColorPicker from '../base/color/HookFormColorPicker'
import TaskbarCheckbox from './component/taskbar-checkbox/TaskbarCheckbox'
import TaskbarSelect from './component/taskbar-select/TaskbarSelect'
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { ResizableComponent } from './ResizableComponent'
import ButtonIcon from '../base/button/ButtonIcon'
import { KTIcon } from 'src/_metronic/helpers'
import { IResponse, Role } from 'src/type'
import {
  TEXT_ALIGN,
  LINE_HEIGHT,
  FONTSIZE,
  FONT_FAMILY,
  TAGS,
  CERTIFICATE_PROFILE,
} from 'src/constants/certificate'
import { CoursesAPI } from 'src/apis/courses'
import { CertificatesApi } from 'src/apis/certificates'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { useConfirm } from 'src/hooks/use-confirm'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import { ResourcesAPI } from 'src/apis/resources'
import ModalUploadFile from 'src/components/base/upload-file/ModalUploadFile/ModalUploadFile'
import HookFormTextArea from 'src/components/base/textaria/HookFormTextArea'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED } from 'src/constants'
import { debounce } from 'lodash'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_CERTIFICATE_GR } from 'src/constants/permission'
import ButtonSecondary from 'src/components/ui//button-secondary/ButtonSecondary'

type Props = {}

const CertificatesTemplate = (props: Props) => {
  const { id } = useParams()
  const { Option } = Select
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [courseCategory, setCourseCategory] = useState<any>()
  const [backgroundImage, setBackgroundImage] = useState<any>(false)
  const [modalUpload, setModalUpload] = useState<boolean>(false)
  const [fileType, setFileType] = useState<any>('IMAGE-DEFAULT')
  const [positionType, setPositionType] = useState<number>(1)
  const [studentName, setStudentName] = useState<any>({
    key: '',
    preview: '',
  })
  const [activeStudentName, setActiveStudentName] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const certificateContentRef = useRef<any>(null)
  const htmlContent = certificateContentRef?.current?.outerHTML
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })

  const schema = z.object({
    name: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
    courseCategoryId: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED),
    elements: z.array(z.any().optional()).default([]),
  })

  const { control, handleSubmit, setValue, watch } = useForm<any>({
    resolver: zodResolver(schema),
  })

  /**
   * Hàm này sẽ tạo một tag HTML tương ứng với value của e
   * @param e - tag
   * @returns Một đối tượng HTML
   */
  function createTag(e: { label: string; value: string }): React.ReactElement {
    if (e.value !== 'p') {
      return React.createElement(e.value, {}, e.label)
    }
    return React.createElement('span', {}, e.label)
  }

  const { append, remove } = useFieldArray({
    control,
    name: 'elements',
  })

  const watchElements = watch('elements')

  const handleAddText = () => {
    append({
      type: 'TEXT',
      tag: 'p',
      fontFamily: 'Be Vietnam Pro,Inter',
      fontSize: '16px',
      color: '#181C32',
      isBold: false,
      isItalic: false,
      isUndefined: false,
      isThrough: false,
      isUpperCase: false,
      textAlign: 'left',
      lineHeight: '1',
      position: {
        top: '200px',
        left: '200px',
        width: '200px',
        height: '100px',
      },
      value: '',
    })
  }

  const handleAddImage = (url: string, fileType: 'IMAGE' | 'TEXT' | 'IMAGE-DEFAULT') => {
    append({
      type: fileType,
      imageUrl: url,
      position: {
        top: '0px',
        left: '0px',
        width: '200px',
        height: '200px',
      },
    })
  }

  const handleDelete = (e: any, i: number, type: string) => {
    if (type === 'TEXT') {
      setActiveStudentName(false)
      remove(i)
    }
  }

  const handleAddTextStudent = () => {
    handleAddText()
    setActiveStudentName(true)
  }

  const getResize = (data: any, index: number) => {
    setValue(`elements.${index}.position`, data)
  }

  const getListText = async () => {
    try {
      const res = await CertificatesApi.getConfigText()
      if (res) {
        setStudentName(res?.data?.student_name)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleImageLoad = (event: any) => {
    const { clientWidth, clientHeight } = event.target
    setImageDimensions({ width: clientWidth, height: clientHeight })
  }

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  // submit form
  const onSubmit = async (data: any) => {
    // Kiểm tra nếu chưa chọn ảnh sẽ thông báo yêu cầu chọn ảnh trước khi lưu
    if (data?.elements.length <= 0) {
      toast.error('Requires Photo Upload Before Saving!')
      return
    }
    const getThisDay = new Date()
    const getDateToday = getThisDay.toISOString()
    const request: any = {
      name: data?.name,
      issuer: 'AWS',
      issue_date: getDateToday,
      courseCategoryId: data?.courseCategoryId,
      setting: data?.elements,
      background: {},
      html_template: `
        <html>
          <head>
            <style>
              body {
                width: ${imageDimensions.width}px;
                height: ${imageDimensions.height}px;
                max-width: 1200px;
                max-height: 1200px;
              }
            </style>
          </head>
          <body>
            ${htmlContent}
          </body>
        </html>
      `,
      text_editor_files: textEditorFiles,
    }

    setLoading(true)

    try {
      let response: IResponse<any>
      if (id) {
        response = await CertificatesApi.editCertificates({ ...request }, id)
      } else {
        response = await CertificatesApi.createCertificates({ data: { ...request } })
      }

      if (response) {
        toast.success('Certificate saved successfully!')
        navigate(CERTIFICATE_PROFILE)
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.code === '422|000405') {
        toast.error('Name Must Be Shorter Than 1000 Characters!')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${CERTIFICATE_PROFILE}`),
    })
  }

  const fetchCourseCategory = async ({ page_index, page_size, params }: any) => {
    try {
      const cleanedParams = {
        status: 'PUBLISH',
      }
      Object.assign(params, cleanedParams)
      const res = await CoursesAPI.getCategory({
        page_index: page_index,
        page_size: page_size,
        params: {
          ...params,
          getType: 'ALL',
        },
      })
      return res
    } catch (error) {
      console.error(error)
    }
  }

  const handlNextCourseCategory = async ({ params }: any) => {
    const totalPages = courseCategory?.metadata?.total_pages
    const pageIndex = courseCategory?.metadata?.page_index
    const pageSize = courseCategory?.metadata?.page_size
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchCourseCategory({
        page_index: pageIndex + 1,
        page_size: pageSize,
        params: params,
      })
      const results = courseCategory.course_categories.concat(res?.data?.course_categories)
      setCourseCategory({
        metadata: res?.data?.metadata,
        course_categories: results,
      })
    }
  }

  const getCourseCategory = async ({ params }: any) => {
    const res = await fetchCourseCategory({ page_index: 1, page_size: 10, params: params })
    if (res) {
      setCourseCategory(res?.data)
    }
  }

  const debounceCourseCategory = debounce((e) => {
    getCourseCategory({ params: { name: e } })
  }, 500)

  const handleSelectFile = async (e: any) => {
    const res = await ResourcesAPI.getUrl(e.id || e?.[0]?.id)
    if (!backgroundImage) {
      setBackgroundImage(true)
      handleAddImage(res.data.url, fileType)
      setFileEditor(e)
    } else {
      setValue(`elements.0.imageUrl`, res.data.url)
    }
  }

  const handleSelectText = (e: any, index: number) => {
    setPositionType(index)
    setValue(`elements.${index}.tag`, e?.tag)
    setValue(`elements.${index}.fontFamily`, e?.fontFamily)
    setValue(`elements.${index}.fontSize`, e?.fontSize)
    setValue(`elements.${index}.textAlign`, e?.textAlign)
    setValue(`elements.${index}.lineHeight`, e?.lineHeight)
  }

  useEffect(() => {
    getListText()
    getCourseCategory({ params: { status: 'PUBLISH' } })
  }, [])

  useEffect(() => {
    const isCheckedImage = watchElements?.some((e: any) => e?.type === 'IMAGE-DEFAULT')
    if (isCheckedImage) {
      setBackgroundImage(true)
    }
  }, [watchElements])

  useLayoutEffect(() => {
    ;(async () => {
      if (id) {
        setLoading(true)
        try {
          const { data } = await CertificatesApi.detail(id)
          if (data) {
            setValue('name', data?.name)
            setValue('courseCategoryId', data?.courseCategoryId)
            data?.setting?.map((e: any) => {
              if (e.type === 'TEXT') {
                append({
                  type: 'TEXT',
                  tag: e?.tag,
                  fontFamily: e?.fontFamily,
                  fontSize: e?.fontSize,
                  color: e?.color,
                  isBold: e?.isBold,
                  isItalic: e?.isItalic,
                  isUndefined: e?.isUndefined,
                  isThrough: e?.isThrough,
                  isUpperCase: e?.isUpperCase,
                  textAlign: e?.textAlign,
                  lineHeight: e?.lineHeight,
                  position: {
                    top: e?.position?.top,
                    left: e?.position?.left,
                    width: e?.position?.width,
                    height: e?.position?.height,
                  },
                  value: e?.value,
                })
                setActiveStudentName(true)
              }

              if (e.type === 'IMAGE-DEFAULT') {
                append({
                  type: e.type,
                  imageUrl: e?.imageUrl,
                  position: {
                    top: e?.position?.top,
                    left: e?.position?.left,
                    width: e?.position?.width,
                    height: e?.position?.height,
                  },
                })
                setBackgroundImage(true)
              }
            })
          }
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [])
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowEditCertificate = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CERTIFICATE_GR.EDIT_CERTIFICATE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowCreateCertificate = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CERTIFICATE_GR.CREATE_CERTIFICATE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowSaveCertificate = id === undefined ? allowCreateCertificate : allowEditCertificate

  return (
    <div className='sapp-certificate'>
      {contextHolder}
      {/* start::header */}
      <div className='sapp-certificate-header bg-white d-flex justify-content-between flex-wrap border-bottom'>
        <div className='sapp-ps-7.5 sapp-pe-7.5 sapp-width-max w-100 d-flex'>
          <div className='d-block'>
            <HookFormTextField
              name='name'
              control={control}
              placeholder='Template Name'
              isListScreen
            ></HookFormTextField>
          </div>
          <div className='d-block ms-5'>
            <HookFormSelectAntd
              name='courseCategoryId'
              control={control}
              placeholder='Category'
              handleNextPage={(e: any) => handlNextCourseCategory({ params: { name: e } })}
              showSearch
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                debounceCourseCategory(e)
                return
              }}
              className='sapp-h-40'
            >
              {courseCategory?.course_categories?.map((item: any, i: number) => {
                return (
                  <Option key={item.id + i} value={item.id} className={'p-4'}>
                    {item?.name}
                  </Option>
                )
              })}
            </HookFormSelectAntd>
          </div>
        </div>
        <div className='sapp-pe-7.5 sapp-ps-7.5 sapp-flex-1'>
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={handleCancel}
            cancelButtonCaption='Cancel'
            okButtonCaption='Save'
            disabled={!allowSaveCertificate}
            okOnClick={() => {
              handleSubmit((data: any) => {
                onSubmit(data)
              })()
            }}
            className='justify-content-end d-flex'
            loading={loading}
            isListScreen
          />
        </div>
      </div>
      {/* end::header */}

      {/* start::content */}
      <div className='sapp-certificate-content position-relative overflow-auto d-flex alight-items-center px-5 pb-5 pt-15'>
        <ModalUploadFile
          open={modalUpload}
          setOpen={setModalUpload}
          setSelectedFile={handleSelectFile}
          fileType={'IMAGE'}
          resourceLocation={RESOURCE_LOCATION.CERTIFICATE}
          object_id={id ? id : undefined}
          is_public
        />
        <div
          ref={certificateContentRef}
          style={{ position: 'relative', width: 'fit-content', margin: 'auto' }}
        >
          {watchElements?.map((e: any, i: number) => {
            if (e.type === 'TEXT') {
              return (
                <ResizableComponent
                  key={i}
                  getResize={getResize}
                  index={i}
                  position={e?.position}
                  parentContentRef={certificateContentRef}
                >
                  <div
                    className='sapp-trash-certificate position-absolute bg-white cursor-pointer'
                    onClick={(event) => {
                      handleDelete(event, i, e.type)
                    }}
                    style={{ display: 'none' }}
                  >
                    <KTIcon
                      iconName='trash'
                      iconType='outline'
                      className='fs-1 text-inverse-light'
                    />
                  </div>
                  <HookFormTextArea
                    control={control}
                    name={`elements.${i}.value`}
                    placeholder='Name'
                    defaultValue={e?.value}
                    onClick={() => handleSelectText(e, i)}
                    className={`${e?.tag}`}
                    id={'sapp-certificate-name'}
                    style={{
                      fontFamily: e?.fontFamily,
                      fontSize: e?.fontSize,
                      color: e?.color,
                      fontWeight: e?.isBold ? '700' : '',
                      fontStyle: e?.isItalic ? 'italic' : 'normal',
                      textDecoration: e?.isThrough
                        ? 'line-through'
                        : e?.isUndefined
                        ? 'underline'
                        : '',
                      textTransform: e?.isUpperCase ? 'uppercase' : 'none',
                      textAlign: e?.textAlign,
                      lineHeight: e?.lineHeight,
                      background: 'transparent',
                      border: 'none',
                      resize: 'none',
                      minHeight: 'calc(1.5em + 1.55rem + 2px)',
                      height: '100%',
                      width: '100%',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      bottom: '0',
                      right: '0',
                      padding: '0',
                      paddingTop: '10px',
                      borderRadius: '0',
                    }}
                  />
                </ResizableComponent>
              )
            }

            if (e.type === 'IMAGE-DEFAULT') {
              return (
                <>
                  <div
                    className='sapp-font-change-image mb-3'
                    onClick={() => {
                      setModalUpload(true)
                      setFileType('IMAGE-DEFAULT')
                    }}
                    style={{
                      position: 'absolute',
                      right: '0',
                      color: '#7E8299',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {/* <span>Choose Image</span> */}
                    {/* <KTIcon iconName='add-files' iconType='outline' className='fs-1 text-inverse-light ms-3' /> */}
                  </div>
                  <div style={{ position: 'relative' }} key={i}>
                    <img
                      src={e?.imageUrl}
                      alt={`Image-${i}`}
                      loading='lazy'
                      draggable='false'
                      style={{
                        objectFit: 'contain',
                        maxWidth: '1200px',
                        maxHeight: '1200px',
                        verticalAlign: 'middle',
                      }}
                      onLoad={handleImageLoad}
                    />
                  </div>
                </>
              )
            }
          })}
        </div>

        {!backgroundImage && (
          <div className='position-absolute rst__icon start-50'>
            <ButtonIcon
              title={'Upload File'}
              className='w-fit-content d-flex align-items-center text-start fs-4 lh-1 fw-bold px-6 py-4 sapp-color-import-student'
              customButton='btn-sm bg-white'
              onClick={(e) => {
                setModalUpload(true)
                setFileType('IMAGE-DEFAULT')
                e?.target?.blur()
              }}
            >
              <KTIcon iconName='file-up' iconType='outline' className='fs-1 text-inverse-light' />
            </ButtonIcon>
          </div>
        )}
      </div>
      {/* end::content */}

      {/* start::taskbar */}
      <div className='sapp-certificate-taskbar bg-white sapp-ps-7.5 sapp-pe-7.5'>
        {backgroundImage && (
          <>
            {/* start::taskbar_left */}
            <div className='sapp-certificate-taskbar_left align-items-center flex-wrap justify-content-between'>
              {/* start::taskbar_tag */}
              <div className='d-flex'>
                <ButtonIcon
                  title={`Add ${studentName?.preview.toLowerCase()}`}
                  className=' px-4 py-0 text-capitalize me-6 sapp-h-45px'
                  onClick={handleAddTextStudent}
                  disabled={activeStudentName ? true : false}
                  customButton='btn-secondary sapp-btn-custom'
                >
                  <KTIcon iconName='plus' className='fs-2' />
                </ButtonIcon>
                <div style={{ position: 'relative', width: 'fit-content', margin: 'auto' }}>
                  {watchElements?.map((e: any, i: number) => {
                    if (e.type === 'IMAGE-DEFAULT') {
                      return (
                        <ButtonSecondary
                          title='Change Image'
                          onClick={() => {
                            setModalUpload(true)
                            setFileType('IMAGE-DEFAULT')
                          }}
                        />
                      )
                    }
                  })}
                </div>
              </div>
              {/* end::taskbar_tag */}
              <div className='d-flex align-items-center'>
                {/* start::taskbar_tag */}
                <HookFormSelectAntd
                  control={control}
                  {...(watchElements.length > 0
                    ? { name: `elements.${positionType}.tag` }
                    : { name: '' })}
                  className='sapp-certificate-taskbar_tag me-2'
                  defaultValue={'p'}
                >
                  {TAGS.map((e) => {
                    return (
                      <Select.Option key={e.value} value={e.value}>
                        {createTag(e)}
                      </Select.Option>
                    )
                  })}
                </HookFormSelectAntd>
                {/* end::taskbar_tag */}
                {/* start::taskbar_font-family */}
                <HookFormSelectAntd
                  control={control}
                  {...(watchElements.length > 0
                    ? { name: `elements.${positionType}.fontFamily` }
                    : { name: '' })}
                  className='sapp-certificate-taskbar_font-family me-2'
                  defaultValue={'Be Vietnam Pro,Inter'}
                >
                  {FONT_FAMILY.map((e) => {
                    return (
                      <Select.Option key={e.value} value={e.value}>
                        <span style={{ fontFamily: e.value }}>{e.label}</span>
                      </Select.Option>
                    )
                  })}
                </HookFormSelectAntd>
                {/* end::taskbar_font-family */}
                {/* start::taskbar_font-size */}
                <div className='sapp-certificate-taskbar_font-size'>
                  <HookFormSelectAntd
                    control={control}
                    {...(watchElements.length > 0
                      ? { name: `elements.${positionType}.fontSize` }
                      : { name: '' })}
                    className='sapp-certificate-taskbar_font-family me-5'
                    defaultValue={'16px'}
                  >
                    {FONTSIZE.map((e) => {
                      return (
                        <Select.Option key={e.value} value={e.value}>
                          {e.label}
                        </Select.Option>
                      )
                    })}
                  </HookFormSelectAntd>
                </div>
                {/* end::taskbar_font-size */}
                {/* start::taskbar_font-style */}
                <div className='sapp-certificate-taskbar_font-style ps-4'>
                  <div className='sapp-certificate-taskbar_text-color'>
                    <CertificatesTemplateIcons
                      icon='text-color'
                      {...(watchElements.length > 0
                        ? { color: watch(`elements.${positionType}.color`) }
                        : { color: '' })}
                    ></CertificatesTemplateIcons>
                    <HookFormColorPicker
                      defaultValue='#181C32'
                      {...(watchElements.length > 0
                        ? { name: `elements.${positionType}.color` }
                        : { name: '' })}
                      control={control}
                    ></HookFormColorPicker>
                  </div>
                  <div className='sapp-certificate-taskbar_bold'>
                    <TaskbarCheckbox
                      watch={watch}
                      {...(watchElements.length > 0
                        ? { name: `elements.${positionType}.isBold` }
                        : { name: '' })}
                      icon='text-bold'
                      control={control}
                    ></TaskbarCheckbox>
                  </div>
                  <div className='sapp-certificate-taskbar_italic'>
                    <TaskbarCheckbox
                      watch={watch}
                      {...(watchElements.length > 0
                        ? { name: `elements.${positionType}.isItalic` }
                        : { name: '' })}
                      icon='text-italic'
                      control={control}
                    ></TaskbarCheckbox>
                  </div>
                  <div className='sapp-certificate-taskbar_undefined'>
                    <TaskbarCheckbox
                      watch={watch}
                      {...(watchElements.length > 0
                        ? { name: `elements.${positionType}.isUndefined` }
                        : { name: '' })}
                      icon='text-underline'
                      control={control}
                    ></TaskbarCheckbox>
                  </div>
                  <div className='sapp-certificate-taskbar_link'>
                    <TaskbarCheckbox
                      watch={watch}
                      {...(watchElements.length > 0
                        ? { name: `elements.${positionType}.isThrough` }
                        : { name: '' })}
                      icon='text-line-through'
                      control={control}
                    ></TaskbarCheckbox>
                  </div>
                  <div className='sapp-certificate-taskbar_upper-case d-none'>
                    <TaskbarCheckbox
                      watch={watch}
                      {...(watchElements.length > 0
                        ? { name: `elements.${positionType}.isUpperCase` }
                        : { name: '' })}
                      icon='upper-case'
                      control={control}
                    ></TaskbarCheckbox>
                  </div>
                  {/* end::taskbar_font-style */}
                </div>
                {/* end::taskbar_left */}

                <div className='sapp-certificate-taskbar_font-format ms-4'>
                  <div className='sapp-certificate-taskbar_text-align'>
                    <TaskbarSelect
                      watch={watch}
                      {...(watchElements.length > 0
                        ? { name: `elements.${positionType}.textAlign` }
                        : { name: '' })}
                      icon='text-align'
                      control={control}
                    >
                      {TEXT_ALIGN.map((e) => {
                        return (
                          <Select.Option key={e.value} value={e.value}>
                            {e.label}
                          </Select.Option>
                        )
                      })}
                    </TaskbarSelect>
                  </div>
                  <div className='sapp-certificate-taskbar_line-paragraph-spacing'>
                    <TaskbarSelect
                      watch={watch}
                      {...(watchElements.length > 0
                        ? { name: `elements.${positionType}.lineHeight` }
                        : { name: '' })}
                      icon='line-paragraph-spacing'
                      control={control}
                    >
                      {LINE_HEIGHT.map((e) => {
                        return (
                          <Select.Option key={e.value} value={e.value}>
                            {e.label}
                          </Select.Option>
                        )
                      })}
                    </TaskbarSelect>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CertificatesTemplate
