import SappDrawer from 'src/components/base/SappDrawer'
import {useForm} from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import {VALIDATION_FILED} from 'src/constants'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {IQuestion, IQuestionDetail} from 'src/type/shop/faqs'
import {FaqsAPI} from 'src/apis/short-course/faqs'
import {Dispatch, SetStateAction, useEffect} from 'react'
import toast from 'react-hot-toast'
import {RESOURCE_LOCATION} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'

interface NewAndUPdate {
  data: IQuestionDetail | null
  isOpen: boolean
  type: 'create' | 'edit'
}

interface IProps {
  open: boolean
  handleClose: () => void
  faqId?: string
  question: IQuestionDetail | null
  type: 'create' | 'edit'
  reload: (faqId: string, page_index: number, page_size: number, params?: Object) => void
  setOpenNewAndUpdate: Dispatch<SetStateAction<NewAndUPdate>>
}

const CreateAndUpdateQuestion = ({
  open,
  handleClose,
  faqId,
  question,
  type,
  reload,
  setOpenNewAndUpdate,
}: IProps) => {
  /**
   * Validate question form
   */
  const validationSchema = z.object({
    title: z.string().min(1, VALIDATION_FILED),
    answer: z.string().min(1, VALIDATION_FILED),
  })

  /**
   * Decalre useHookForm
   */
  const {control, handleSubmit, setValue, getValues, reset} = useForm<IQuestion>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  /**
   * Handle submit for updating and creating
   */
  const onSubmit = async (data: IQuestion) => {
    try {
      if (!faqId) return
      if (question && type === 'edit') {
        await FaqsAPI.editQuestionFAQ(faqId, question.id, data)
        toast.success('Question updated successfully!')
      } else {
        await FaqsAPI.createQuestionFAQ(faqId, data)
        toast.success('Question created successfully!')
      }
      reload(faqId, 1, 10)
      setOpenNewAndUpdate({isOpen: false, data: null, type: 'create'})
    } catch (err) {
      // DO NOTHING
    }
  }

  const loadData = () => {
    reset()
    if (question !== null) {
      setValue('title', question?.title)
      setValue('answer', question?.answer)
    }
  }

  useEffect(() => {
    loadData()
  }, [question, faqId])

  return (
    <SappDrawer
      open={open}
      title='New Question'
      handleSubmit={handleSubmit(onSubmit)}
      width='50%'
      handleClose={handleClose}
      okButtonCaption='Save'
    >
      <div className='mb-10'>
        <HookFormTextField
          control={control}
          name='title'
          label='Title'
          required
          className='sapp-h-45px'
        />
      </div>
      <div className='mb-10'>
        <HookFormEditor
          label='Answer'
          control={control}
          name='answer'
          math={true}
          height={370}
          required
          defaultValue={getValues('answer')}
          resourceLocation={RESOURCE_LOCATION.MEDIA}
          object_id={undefined}
        />
      </div>
    </SappDrawer>
  )
}

export default CreateAndUpdateQuestion
