import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {zodResolver} from '@hookform/resolvers/zod'
import {SubmitHandler, useForm} from 'react-hook-form'
import {CoursesAPI} from 'src/apis/courses'
import HookFormTextArea from 'src/components/base/textaria/HookFormTextArea'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {
  VALIDATE_MAX_DESCRIPTION,
  VALIDATE_MAX_NAME,
  VALIDATE_MIN_NAME,
  VALIDATION_FILED,
} from 'src/constants'
import {z} from 'zod'
import {isEmpty} from 'lodash'
import {ICourseCategory} from 'src/type/courses'
import {toast} from 'react-hot-toast'
import SappDrawer from 'src/components/base/SappDrawer'
import {useConfirm} from 'src/hooks/use-confirm'

interface IProps {
  open: boolean
  handleClose: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
  id: string
  fetchCourseCategory: any
  currentPage: number
  pageSize: number
  loading: boolean
  disabled?: boolean
}

interface IInputProps {
  name: string
  description: string
}

const CreateAndUpdateCategory = ({
  handleClose,
  open,
  setLoading,
  id,
  fetchCourseCategory,
  currentPage,
  pageSize,
  loading,
  disabled,
}: IProps) => {
  const [categoryDetail, setCategoryDetail] = useState<ICourseCategory>()
  const {confirm, contextHolder} = useConfirm()

  // Validate for input
  const validationSchema = z.object({
    name: z
      .string({required_error: VALIDATION_FILED})
      .min(1, {message: VALIDATE_MIN_NAME})
      .max(100, {message: VALIDATE_MAX_NAME}),
    description: z
      .string({required_error: VALIDATION_FILED})
      .max(2000, {message: VALIDATE_MAX_DESCRIPTION})
      .optional(),
  })

  // Using validate for input
  const {control, handleSubmit, reset, setValue} = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const handleResetForm = () => {
    handleClose()
    reset()
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => {
        handleResetForm()
      },
    })
  }

  const onSubmitCreate = async (data: IInputProps) => {
    setLoading(true)
    try {
      const res = await CoursesAPI.createCategory(data)
      if (res.success) {
        fetchCourseCategory(currentPage || 1, pageSize || 10)
      }
      handleResetForm()
      toast.success('Create Successfully!')
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmitUpdate = async (data: IInputProps) => {
    setLoading(true)
    try {
      const res = await CoursesAPI.updateCategory(id, data)
      if (res.success) {
        fetchCourseCategory(currentPage || 1, pageSize || 10)
      }

      handleResetForm()
      toast.success('Update Successfully!')
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<IInputProps> = async (data) => {
    if (id) {
      onSubmitUpdate(data)
      return
    }
    onSubmitCreate(data)
  }

  const fetchCategoryDetail = async () => {
    try {
      const res = await CoursesAPI.getCategoryDetail(id)
      setCategoryDetail(res.data)
    } catch (error) {}
  }

  useEffect(() => {
    if (!isEmpty(id)) {
      fetchCategoryDetail()
    }
  }, [id, open])

  useEffect(() => {
    if (open && id) {
      setValue('name', categoryDetail?.name ?? '')
      setValue('description', categoryDetail?.description ?? '')
    }
  }, [setValue, id, categoryDetail?.name, categoryDetail?.description, open])

  return (
    <SappDrawer
      rootClassName='sapp-question_essay-drawer'
      open={open}
      title={id ? 'Edit Category' : 'Create Category'}
      cancelButtonCaption={'Cancel'}
      okButtonCaption={'Save'}
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={handleCancel}
      disabled={disabled}
      width='50%'
      loading={loading}
    >
      {contextHolder}
      <div className='mb-8 fv-row'>
        <HookFormTextField control={control} name='name' label='Name' required placeholder='Name' />
      </div>
      <div className='mb-8 fv-row'>
        <HookFormTextArea control={control} name='description' label='Description' rows={6} />
      </div>
    </SappDrawer>
  )
}

export default CreateAndUpdateCategory
