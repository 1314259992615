/* eslint-disable @typescript-eslint/no-unused-vars */
import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { TagAPI } from 'src/apis/short-course/tags'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ListGrouping from 'src/components/base/list-grouping/ListGrouping'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/ListFilterLayout'
import PageLayouts from 'src/components/layout/PageLayouts'
import CreateAndUpdateTags from 'src/components/tags/CreateAndUpdateTags'
import TableTags from 'src/components/tags/TableTags'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { DELETE_POPUPCONFIRM, LANG_NEWS } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { ITabs } from 'src/type'
import { INewOrUpdatePopup } from 'src/type/shop/common'
import { IInitialValue, ITagList } from 'src/type/shop/tag'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: '',
    title: 'Shop',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Blog',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Tags',
  },
]

const ListTags = () => {
  // Using validate for input
  const [openNewOrUpdate, setOpenNewOrUpdate] = useState<INewOrUpdatePopup>({
    isOpen: false,
    data: null,
    type: 'create',
  })
  const [tagList, setTagList] = useState<ITagList>()
  const [loading, setLoading] = useState<boolean>(false)
  const { confirm, contextHolder } = useConfirm()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [dateField, setDateField] = useState<string>('created_at')
  const page_index = parseInt(searchParams.get('page_index') as string)
  const page_size = parseInt(searchParams.get('page_size') as string)

  const fieldNames = ['name', 'sortType', 'fromDate', 'toDate']

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<IInitialValue>(
    tagList?.blogTags
  )

  const validationSchema = z.object({
    name: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  // Using validate for input
  const { control, handleSubmit, getValues, reset, watch, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const fetchCategories = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await TagAPI.getTags({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setTagList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleResetFilter = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, '')
    })

    setLoading(true)
    navigate(`${PageLink.TAGS}`)
    setDateField('')
    toggleCheckAll(false)
    fetchCategories(1, 10)
  }

  const navigate = useNavigate()

  const getParams = (
    name: string,
    sort_type: string,
    fromDate?: Date | string,
    toDate?: Date | string
  ) => ({
    name,
    sort_type,
    fromDate: fromDate,
    toDate: toDate,
  })

  const queryParam = {
    name: searchParams.get('name'),
    sort_type: searchParams.get('sort_type'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: getValues('name'),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('lastRegistry')?.fromDate) ?? '',
      toDate: formatDate(getValues('lastRegistry')?.toDate) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('lastRegistry')?.fromDate)
    const dateInfoToDate = getDateInfo(getValues('lastRegistry')?.toDate)

    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name')?.trim(),
        replaceValueAll(getValues('sort_type')),
        getValues('lastRegistry')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('lastRegistry')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : ''
      )
    )
    handleChangeParams(page_index || 1, page_size || 10)

    setLoading(true)
    fetchCategories(1, 10, cleanedParams)
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParam.fromDate)
  const dateQueryToDate = getDateInfo(queryParam.toDate)
  const cleanedParams = cleanParamsAPI(
    getParams(
      queryParam.name ?? '',
      queryParam.sort_type ?? '',
      queryParam.fromDate
        ? formatISOToDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParam.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : ''
    )
  )
  const fetchPage = (refresh: boolean = true) => {
    const params = new URLSearchParams(location.search)
    const page_index = params.get('page_index') && refresh ? Number(params.get('page_index')) : 1
    const page_size = params.get('page_size') && refresh ? Number(params.get('page_size')) : 10
    const name = params.get('name')
    fetchCategories(page_index, page_size, { name })
  }

  const deleteBulkTag = async (): Promise<void> => {
    if (!checkedList || checkedList.length <= 0) {
      return
    }
    setLoading(true)
    try {
      await TagAPI.deleteBulkTags(checkedList)
      fetchPage(false)
      toast.success('Tags deleted successfully!')
    } catch (error: any) {
      // do nothing
    } finally {
      toggleCheckAll(false)
      setLoading(false)
    }
  }

  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => {
        setOpenNewOrUpdate({ isOpen: false, data: null, type: 'create' })
      },
    })
  }

  return (
    <PageLayouts pageTitle='Tags' breadcrumbs={breadcrumbs}>
      {contextHolder}
      <div className='card'>
        {/* start:: filter */}
        <div className='px-10 border-0 pt-10'>
          <ListFilterLayout>
            {/*  Search */}
            <HookFormTextField
              control={control}
              name='name'
              placeholder='Search tag'
              isListScreen
            />

            {/* Sort By */}
            <HookFormSelectAntd
              size='large'
              control={control}
              name='sort_type'
              placeholder='Sort By'
              showSearch
              classNameHeight='sapp-h-40'
            >
              {FILTER_SELECTALL_SORTBY.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            {/* Date */}
            <RangeDateTimePicker
              control={control}
              name='lastRegistry'
              allowClear={true}
              placeholder={['From date', 'To date']}
              className='sapp-h-datimepicker sapp-width-picker-range'
              isListScreen
            />
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-5 px-10'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={handleSubmit(onSubmit)}
              resetClick={handleResetFilter}
              disabled={false}
            />
          </div>

          <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0'>
            <div className='card-toolbar justify-content-end d-flex my-0 gap-2'>
              {checkedList.length > 0 && (
                <ListGrouping
                  selected={checkedList}
                  okClick={deleteBulkTag}
                  title={LANG_NEWS.deleteSelected}
                  body={DELETE_POPUPCONFIRM}
                  okButtonCaption='Yes'
                />
              )}
              {/* start:: button open modal */}
              <ButtonIcon
                title='New Tag'
                onClick={() => setOpenNewOrUpdate({ isOpen: true, data: null, type: 'create' })}
                isListScreen
              >
                <KTIcon iconName='plus' className='fs-2' />
              </ButtonIcon>
              {/* end:: button open modal */}
            </div>
          </div>
        </div>

        {/* end:: filter */}

        <CreateAndUpdateTags
          setLoading={setLoading}
          setOpenNewCategory={setOpenNewOrUpdate}
          reloadPage={fetchPage}
          open={openNewOrUpdate?.isOpen}
          handleClose={handleClose}
          tag={openNewOrUpdate.data}
          type={openNewOrUpdate.type}
        />
        {/* start:: table */}
        <TableTags
          tagList={tagList}
          setTagList={setTagList}
          cleanedParams={cleanedParams}
          loading={loading}
          setLoading={setLoading}
          getParams={getParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
          loadData={fetchCategories}
          queryParam={queryParam}
          reloadPage={fetchPage}
          setOpenNewOrUpdate={setOpenNewOrUpdate}
        />
        {/* end:: table */}
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ListTags)
