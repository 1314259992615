import { useEffect, useState } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import { QuestionBankAPI } from 'src/apis/question-bank'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import { QuestionProviderProvider } from 'src/components/question-bank/shared/providers/QuestionProvider'
import VideoTimeLine from 'src/components/video-question/videoTimeLine'
// import SettingTab from './tabSettings/TabSetting'
import TabVideoQuestion from './tabVideoQuestion/TabVideoQuestion'
import { z } from 'zod'
import {
  VALIDATE_NUMBER,
  VALIDATE_PASS_POINT,
  VALIDATION_FILED,
  VALIDATION_MIN_EQUAL,
} from 'src/constants'
import { useForm } from 'react-hook-form'
import {
  ATTEMPT,
  GRADED_ASSIGNMENT,
  GRADING_PREFERENCE,
  PASS_POINT,
  TEST_MODE,
  TYPE_OF_TEST,
} from 'src/components/courses/shared/consts'
import { zodResolver } from '@hookform/resolvers/zod'
import { htmlToRaw } from 'src/utils'
import { useConfirm } from 'src/hooks/use-confirm'
import { ResourcesAPI } from 'src/apis/resources'
import toast from 'react-hot-toast'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'

interface IProps {
  open: boolean
  setOpen: any
  // url?: string
  // name?: string
  // quiz_id?: string
  // time_line?:any
  tabActive?: number
  resource_id?: string
}
export interface IQuiz {
  name: string
  type: string
  testMode: string
  gradedAssignment: string
  time: {
    hours: string
    mins: string
  }
  duration: {
    mins: string
    sec: String
  }
  gradingPreference: string
  passPoint: {
    type: string
    value: string
  }
  attempt: {
    type: string
    value: string
  }
}
const defaultValues = {
  name: '',
  type: TYPE_OF_TEST.multipleChoice.value,
  testMode: TEST_MODE.tutor.value,
  gradedAssignment: GRADED_ASSIGNMENT.no.value,
  time: {
    hours: '',
    mins: '',
  },
  duration: {
    mins: '',
    sec: '',
  },
  gradingPreference: GRADING_PREFERENCE.each.value,
  passPoint: {
    type: PASS_POINT.auto.value,
    value: '',
  },
  attempt: {
    type: ATTEMPT.unlimited.value,
    value: '',
  },
}
const VideoSetting = ({ open, setOpen, resource_id, tabActive }: IProps) => {
  useEffect(() => {
    async function getData() {
      if (resource_id) {
        const res = await ResourcesAPI.getUrl(resource_id)
        setData(res.data)
      }
    }
    if (resource_id) {
      getData()
    }
  }, [resource_id])
  const [data, setData] = useState<
    | {
        quiz_id: string
        sub_url: string
        time_line?: Array<any>
        name: string
      }
    | undefined
  >()
  const { confirm, contextHolder } = useConfirm()
  const [activeTab, setActiveTab] = useState(0)
  //handle load video done, then fill time by duration
  const [firstLoad, setFirstLoad] = useState(true)
  //handle block submit on submiting
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [currentTime, setCurrentTime] = useState<any>(null)
  const [listTimeLine, setListTimeLine] = useState<
    | {
        menuName: string
        questionId: string
        time: number
      }[]
    | []
  >([])
  const [listTimeLineMenu, setListTimeLineMenu] = useState<
    | {
        menuName: string
        time: number
      }[]
    | []
  >([])
  const [duration, setDuration] = useState<any>()
  const [openQuestionSelect, setOpenQuestionSelect] = useState<any>({
    state: false,
    data: {},
    edit: false,
    index: undefined,
  })
  const [openMenuSelect, setOpenMenuSelect] = useState<any>({
    state: false,
    data: {},
    edit: false,
  })
  const [sortedListTimeLine, setSortedListTimeLine] = useState<any>()
  const [sortedListTimeLineMenu, setSortedListTimeLineMenu] = useState<any>()
  const validationSchema = z.object({
    name: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    type: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    testMode: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    gradedAssignment: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED),
    time: z.object({
      hours: z
        .string()
        .regex(/^[0-9]*$/, VALIDATE_NUMBER)
        .refine(
          (val) => {
            if (
              getValues('testMode') === TEST_MODE.timed.value &&
              !Number(val) &&
              !Number(getValues('time.mins'))
            ) {
              return false
            }
            return true
          },
          { message: VALIDATION_FILED }
        )
        .optional(),
      mins: z
        .string()
        .regex(/^[0-9]*$/, VALIDATE_NUMBER)
        .refine(
          (val) => {
            if (
              !Number(val) &&
              !Number(getValues('time.hours')) &&
              getValues('testMode') === TEST_MODE.timed.value
            ) {
              return false
            }
            return true
          },
          { message: VALIDATION_FILED }
        )
        .optional(),
    }),

    gradingPreference: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED),
    passPoint: z.object({
      type: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
      value: z
        .string()
        .regex(/^[0-9]*$/, VALIDATE_NUMBER)
        .superRefine((arg: any, ctx) => {
          if (getValues('passPoint.type') === PASS_POINT.manual.value) {
            if (!Number(arg) || Number(arg) > 100) {
              ctx.addIssue({
                message: VALIDATE_PASS_POINT,
                code: 'custom',
              })
            }
          }
          return true
        })
        .optional(),
    }),
    attempt: z.object({
      type: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
      value: z
        .string()
        .regex(/^[0-9]*$/, VALIDATE_NUMBER)
        .refine(
          (val) => {
            if (getValues('attempt.type') === ATTEMPT.limited.value && (!val || Number(val) < 1)) {
              return false
            }
            return true
          },
          { message: VALIDATION_MIN_EQUAL(1) }
        )
        .optional(),
    }),
    quiz_question_type: z.string().optional(),
  })
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { isSubmitted },
    trigger,
    clearErrors,
  } = useForm<IQuiz>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  // const watchTestMode = watch('testMode')
  const watchHours = watch('time.hours')
  const watchMins = watch('time.mins')
  const [editAble, setEditAble] = useState(true)
  //handle sort list timeline base on time
  useEffect(() => {
    const newListTimeLine = [...listTimeLine]
    newListTimeLine.sort((a, b) => a.time - b.time)
    setSortedListTimeLine(newListTimeLine)
  }, [listTimeLine])
  useEffect(() => {
    const newListTimeLine = [...listTimeLineMenu]
    newListTimeLine.sort((a, b) => a.time - b.time)
    setSortedListTimeLineMenu(newListTimeLine)
  }, [listTimeLineMenu])
  useEffect(() => {
    async function getQuizDetail() {
      if (data?.quiz_id) {
        try {
          const result = await QuestionBankAPI.getQuizDetail(data?.quiz_id)
          const listQuestion = result.data.multiple_choice_questions.concat(
            result.data.constructed_questions
          )
          // const listMenu = [...result.data.time_line]
          const listTimeLineData = [...listQuestion]
          handleConvertListTimeLine(listTimeLineData)

          // handleConvertListTimeLineMenu(listMenu)
          // for (let timeline of listTimeLineData) {
          // }
          const oldData = convertResultToFormValues(result)
          Object.entries(oldData || {}).forEach(([key, value]: [any, any]) => {
            setValue(key, value)
          })
          if (result.data.status === 'LOCKED') {
            setEditAble(false)
          } else {
            setEditAble(true)
          }

          setFirstLoad(false)
        } catch (err: any) {
          if (err?.response?.status === 422) {
            toast.error('Quiz not found!')
          }
          console.error(err)
        }
      }
    }
    if (data?.quiz_id) {
      getQuizDetail()
    }
  }, [data])
  useEffect(() => {
    if (tabActive !== undefined) {
      setActiveTab(tabActive)
    }
  }, [tabActive, open])
  useEffect(() => {
    if (data?.time_line) {
      const listMenu = [...data?.time_line]
      handleConvertListTimeLineMenu(listMenu)
    }
  }, [data?.time_line])

  useEffect(() => {
    if (duration > 0 && watchHours === '0' && watchMins === '0' && !firstLoad) {
      setValue('time.mins', Math.ceil(duration / 60)?.toString())
    }
  }, [duration, firstLoad])
  const handleConvertListTimeLine = (data: any) => {
    let newArr = []
    for (let e of data) {
      if (e != null) {
        newArr.push({
          menuName: htmlToRaw(e.question_content),
          questionId: e.id,
          time: e.time,
        })
      }
    }
    setListTimeLine(newArr)
  }
  const handleConvertListTimeLineMenu = (data: any) => {
    let newArr = []
    for (let e of data) {
      if (e != null) {
        newArr.push({
          menuName: e.text,
          time: e.time,
        })
      }
    }
    setListTimeLineMenu(newArr)
  }
  //convert data to fill form
  function convertResultToFormValues(result: any) {
    return {
      name: result.data.name,
      type: result.data.quiz_type.toLowerCase(),
      testMode: result.data.is_tutor ? TEST_MODE.tutor.value : TEST_MODE.timed.value,
      gradedAssignment: result.data.is_graded
        ? GRADED_ASSIGNMENT.yes.value
        : GRADED_ASSIGNMENT.no.value,
      time: {
        hours: Math.floor(result.data.quiz_timed / 60)?.toString(),
        mins: (result.data.quiz_timed % 60)?.toString(),
      },
      gradingPreference:
        result.data.grading_preference === 'AFTER_EACH_QUESTION'
          ? GRADING_PREFERENCE.each.value
          : GRADING_PREFERENCE.all.value,
      passPoint: {
        type:
          result.data.grading_method === 'AUTO' ? PASS_POINT.auto.value : PASS_POINT.manual.value,
        value:
          result.data.grading_method === 'AUTO'
            ? ''
            : result.data.required_percent_score
            ? result.data.required_percent_score?.toString()
            : '',
      },
      attempt: {
        type: result.data.is_limited ? ATTEMPT.limited.value : ATTEMPT.unlimited.value,
        value: result.data.is_limited
          ? result.data.limit_count
            ? result.data.limit_count?.toString()
            : ''
          : '',
      },
      quiz_question_type: result.data.quiz_question_type,
    }
  }
  const onSubmit = async (dataValue: any) => {
    let quiz_timeline = []
    let time_line_text = []
    try {
      setLoadingSubmit(true)
      for (let e of sortedListTimeLine) {
        quiz_timeline.push({
          time: e.time,
          question_id: e.questionId,
        })
      }
      for (let e of sortedListTimeLineMenu) {
        time_line_text.push({
          time: e.time,
          text: e.menuName,
        })
      }

      const payload = {
        name: dataValue.name,
        quiz_question_mode: 'ALL',
        is_published: true,
        is_public: true,

        number_of_multiple_choice_questions:
          Number(dataValue.number_of_multiple_choice_questions) || 0,
        number_of_essay_questions: Number(dataValue.number_of_essay_questions) | 0,

        is_graded: dataValue.gradedAssignment === GRADED_ASSIGNMENT.yes.value ? true : false,

        grading_method: dataValue.passPoint.type,
        grading_preference: GRADING_PREFERENCE.each.value,
        ...(dataValue.passPoint.type === PASS_POINT.manual.value
          ? { required_percent_score: +dataValue.passPoint.value }
          : { required_percent_score: 50 }),

        is_limited: dataValue.attempt.type === ATTEMPT.limited.value ? true : false,
        ...(dataValue.attempt.type === ATTEMPT.limited.value
          ? {
              limit_count: Number(dataValue.attempt.value) || 0,
            }
          : { limit_count: 0 }),

        is_tutor: dataValue.testMode === TEST_MODE.tutor.value ? true : false,
        ...(dataValue.testMode === TEST_MODE.timed.value && {
          quiz_timed: Number(dataValue.time.hours) * 60 + Number(dataValue.time.mins),
        }),
        quiz_type: 'QUIZ',
        quiz_question_type: dataValue.quiz_question_type,
        quiz_timeline: quiz_timeline,
        time_line: time_line_text,
      }
      await QuestionBankAPI.updateQuizVideo({
        id: data?.quiz_id,
        data: payload,
      })
      reset()
      setListTimeLine([])
      setListTimeLineMenu([])
      setOpen({ status: false })
      setDuration(0)
      setCurrentTime(0)
      setFirstLoad(true)
      setActiveTab(0)
      setData(undefined)
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingSubmit(false)
    }
  }
  const handleClose = () => {
    let body = ['Bạn có chắc chắn muốn huỷ sửa video này không?']
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        reset()
        setListTimeLine([])
        setListTimeLineMenu([])
        setOpen({ state: false })
        setDuration(0)
        setCurrentTime(0)
        setFirstLoad(true)
        setData(undefined)
        setActiveTab(0)
      },
    })
  }

  //handle delete item
  const handleDeleteTimeline = (e: any) => {
    if (activeTab === 1) {
      setSortedListTimeLine((prev: any) => {
        let arr = [...prev]
        // return arr.filter((item: any) => item.time !== e)
        arr.splice(e, 1)
        setListTimeLine(arr)
        return arr
      })
    } else {
      setSortedListTimeLineMenu((prev: any) => {
        let arr = [...prev]
        // return arr.filter((item: any) => item.time !== e)
        arr.splice(e, 1)
        setListTimeLineMenu(arr)
        return arr
      })
    }
  }

  //show drawer when tab is questions
  const handleEditTimeLine = (e: number) => {
    if (activeTab === 1) {
      setOpenQuestionSelect({ state: true, edit: true, data: sortedListTimeLine[e], index: e })
    }
  }
  return (
    <QuestionProviderProvider>
      <SappModal
        open={open}
        title=''
        hideHeader={true}
        cancelButtonCaption='cancel'
        handleCancel={handleClose}
        showFooter={false}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen'}
        classBody='p-0 h-100'
        classNameBody={'sapp-m-h-unset h-100 sapp-modal-body-cutsom'}
        enforceFocus={false}
      >
        {/* <Spin spinning={firstLoad}> */}
        {contextHolder}
        <div className='d-flex h-100'>
          {/* <button onClick={handleSubmit(onSubmit)}></button> */}
          <div
            className='d-flex flex-column h-100 bg-white sapp-tabside-container'
            style={{ position: 'fixed', zIndex: 99999999 }}
          >
            <div className='flex-grow-1'>
              <ButtonIcon
                title='Timeline'
                classTitle='sapp-title-video-setting'
                className={
                  'd-flex flex-column align-items-center justify-content-center sapp-tab-btn-video gap-2'
                }
                customButton={`btn-active-light-primary ${
                  activeTab === 0 && 'active'
                } rounded-0 bg-white`}
                onClick={() => setActiveTab(0)}
                isHoverCustom={false}
              >
                <KTIcon
                  iconName='menu'
                  className={`fs-2x p-0 ${activeTab !== 0 && 'sapp-icon-btn-tab'}`}
                  iconType='solid'
                />
              </ButtonIcon>
              <ButtonIcon
                title={'Question'}
                classTitle='sapp-title-video-setting'
                className={
                  'd-flex flex-column align-items-center justify-content-center sapp-tab-btn-video gap-2'
                }
                customButton={`btn-active-light-primary ${
                  activeTab === 1 && 'active'
                } rounded-0 bg-white`}
                onClick={() => setActiveTab(1)}
                isHoverCustom={false}
              >
                <KTIcon
                  iconName='question'
                  className={`fs-2x p-0 ${activeTab !== 1 && 'sapp-icon-btn-tab'}`}
                  iconType='solid'
                />
              </ButtonIcon>
              {/* <ButtonIcon
                title='Settings'
                classTitle='sapp-title-video-setting'
                className={
                  'd-flex flex-column align-items-center justify-content-center sapp-tab-btn-video gap-2'
                }
                customButton={`btn-active-light-primary ${
                  activeTab === 2 && 'active'
                } rounded-0 bg-white`}
                onClick={() => setActiveTab(2)}
              >
                <KTIcon
                  iconName='setting'
                  className={`fs-2x p-0 ${activeTab !== 2 && 'sapp-icon-btn-tab'}`}
                  iconType='solid'
                />
              </ButtonIcon> */}
            </div>
            {/* <div className='d-flex justify-content-center align-items-center'>
              <ButtonIconOnly
                iconName='question-2'
                fs='2x'
                type='button'
                iconType='solid'
                onClick={() => {}}
              />
            </div> */}
          </div>
          <div className='w-100 h-100 sapp-video-setting-content-container'>
            <div className='d-flex w-100 h-100'>
              <div className='sapp-added-question-container'>
                <div className='w-100'>
                  {activeTab === 1 && (
                    <div className='px-8 pt-8'>
                      <HookFormTextField
                        control={control}
                        name='name'
                        placeholder='Quiz Name'
                        disabled={!editAble}
                        // skeleton={loadingFetchData}
                        label='Quiz Name'
                        required
                      ></HookFormTextField>
                    </div>
                  )}

                  {/* {duration ? (
                    <> */}
                  <TabVideoQuestion
                    actionDelete={handleDeleteTimeline}
                    tab={activeTab}
                    setOpenQuestionSelect={
                      activeTab === 1 ? setOpenQuestionSelect : setOpenMenuSelect
                    }
                    listTimeLine={activeTab === 1 ? sortedListTimeLine : sortedListTimeLineMenu}
                    currentTime={currentTime}
                    setListTimeLine={activeTab === 1 ? setListTimeLine : setListTimeLineMenu}
                    actionEdit={handleEditTimeLine}
                  />
                </div>
                {/* {activeTab === 2 && (
                  <SettingTab
                    id={data?.quiz_id || ''}
                    clearErrors={clearErrors}
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    watch={watch}
                    editAble={editAble}
                    isSubmitted={isSubmitted}
                  />
                )} */}
                {/* </> */}
                {/* ) : (
                    <></>
                  )} */}
              </div>
              <div className='sapp-video-container p-8 ps-12 pe-12 pb-14'>
                <div className='d-flex  justify-content-between mb-8'>
                  <div className='sapp-questionTab-title'>{data?.name}</div>
                  <div className={`flex-end`}>
                    <SAPPDialogButtonsCancelSubmit
                      cancelClick={() => {
                        handleClose()
                      }}
                      cancelButtonCaption={'Cancel'}
                      okButtonCaption={'Finish'}
                      okOnClick={
                        handleSubmit(onSubmit)
                        // reset()
                      }
                      loading={loadingSubmit}
                      className='justify-content-center d-flex m-0'
                      // loading={loading}
                    />
                  </div>
                </div>
                {data?.sub_url && (
                  <VideoTimeLine
                    url={data?.sub_url}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                    listTimeLine={listTimeLine}
                    setListTimeLine={setListTimeLine}
                    duration={duration}
                    setDuration={setDuration}
                    openQuestionSelect={openQuestionSelect}
                    setOpenQuestionSelect={setOpenQuestionSelect}
                    openMenuSelect={openMenuSelect}
                    setOpenMenuSelect={setOpenMenuSelect}
                    setListTimeLineMenu={setListTimeLineMenu}
                    setSortedListTimeLine={setSortedListTimeLine}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* </Spin> */}
      </SappModal>
    </QuestionProviderProvider>
  )
}
export default VideoSetting
