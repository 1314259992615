import { IResponse } from 'preview-activity/dist/shared/interfaces'
import { IUpdateTeacher } from 'src/components/teacher/TeacherPostForm/TeacherPostForm'
import { fetcher, fetchFormData } from 'src/services/request'
import { IClassCourseList } from 'src/type/class-user'
import { IStudentAll, IStudents } from 'src/type/students'
import { ITeacher } from 'src/type/teacher'

export class UsersAPI {
  static detail(id: string | undefined): Promise<IStudentAll> {
    return fetcher(`/users/${id}`)
  }

  static update({ id, data }: { id: string | undefined; data: IUpdateTeacher }): Promise<any> {
    return fetcher(`/users/${id}`, {
      data: data,
      method: 'PUT',
    })
  }

  static blocked = async (id: Array<string> | string) => {
    return fetcher(`users/block`, {
      method: 'PATCH',
      data: {
        userIds: id,
      },
    })
  }

  static universities = () => {
    return fetcher('universities')
  }

  static myprofile = async () => {
    return fetcher('/me')
  }

  static sendOTP(id: string | undefined): Promise<any> {
    return fetcher(`users/${id}/send-change-email-otp`, {
      method: 'POST',
    })
  }

  static changeEmail(id: string | undefined, email: string, code: string): Promise<any> {
    return fetcher(`users/${id}/change-email`, {
      data: {
        email,
        code,
      },
      method: 'PATCH',
    })
  }

  static updateStaffStatus({ id, status }: { id: string | undefined; status: any }): Promise<any> {
    return fetcher(`/staffs/${id}`, {
      data: { status },
      method: 'PUT',
    })
  }

  static updateUserStatus({ id, status }: { id: string | undefined; status: any }): Promise<any> {
    return fetcher(`/users/${id}`, {
      data: { status },
      method: 'PUT',
    })
  }
  static makeUseContactDefault(data: { userId: string; userContactId: string }) {
    return fetcher(`/users/contacts/make-this-default`, { method: 'POST', data })
  }
}

export const updateStudent = async ({ id, data }: { id: string | undefined; data: any }) => {
  return fetcher(`/users/${id}`, {
    method: 'PUT',
    data: data,
  })
}

export const getListActivities = async ({
  id,
  page_index,
  page_size,
}: {
  id: string
  page_index: number
  page_size: number
}) => {
  return fetcher(`/users/${id}/activities?page_index=${page_index}&page_size=${page_size}`)
}

export const getListDevices = async ({ id }: { id: string | undefined }) => {
  return fetcher(`/users/${id}/devices`)
}

export const deleteListDevices = async ({
  user_id,
  session_id,
}: {
  user_id: string
  session_id: string
}) => {
  return fetcher(`/users/${user_id}/devices/${session_id}`, {
    method: 'DELETE',
  })
}

export const resetPassUser = async (id: string) => {
  return fetcher(`/users/${id}/reset-password`, {
    method: 'PATCH',
  })
}

export const uploadFile = ({
  userId,
  avatar,
  id_front,
  id_back,
}: {
  userId: string | undefined
  avatar?: File
  id_front?: File
  id_back?: File
}) => {
  const formData = new FormData()
  if (userId) {
    formData.append('userId', userId)
  }

  if (avatar) {
    formData.append('avatar', avatar)
  }
  if (id_front) {
    formData.append('id_front', id_front)
  }
  if (id_back) {
    formData.append('id_back', id_back)
  }

  return fetchFormData({ url: `/users/detail/upload`, formData })
}

export const createStudent = ({
  username,
  password,
  email,
  phone,
  level,
  acca_level,
  cma_level,
  full_name,
  hubspot_contact_id,
  status,
  examination_code,
  sex,
  is_sapp_operator,
  type_user,
  employee_code,
}: IStudents) => {
  return fetcher(`/users`, {
    method: 'POST',
    data: {
      username,
      password,
      email,
      phone,
      level,
      acca_level,
      cma_level,
      full_name,
      hubspot_contact_id,
      status,
      examination_code,
      type: 'STUDENT',
      sex,
      is_sapp_operator,
      type_user,
      employee_code,
    },
  })
}

export const createTeacher = ({
  username,
  password,
  email,
  phone,
  level,
  full_name,
  status,
  sex,
  job_title,
  linkedin,
  facebook,
  youtube,
  describe,
  teacher_teachable_instances,
  staff_ids,
  teacher_status,
  category_primary_id,
}: ITeacher) => {
  return fetcher(`/users`, {
    method: 'POST',
    data: {
      username,
      password,
      email,
      phone,
      level,
      full_name,
      status,
      sex,
      type: 'TEACHER',
      job_title,
      linkedin,
      facebook,
      youtube,
      describe,
      teacher_teachable_instances,
      staff_ids,
      teacher_status,
      category_primary_id,
    },
  })
}

export const getAPIUser = ({
  params,
  page_index,
  page_size,
  type,
}: {
  params?: Object
  page_index: number
  page_size: number
  type?: 'TEACHER' | 'STUDENT'
}) => {
  return fetcher(
    `/users?page_index=${page_index}&page_size=${page_size}${type ? `&type=${type}` : ''}`,
    {
      params: params,
    }
  )
}

export const getPermissionReport = () => {
  return fetcher(`permissions/state-permission-initial`, {})
}

export const getAPIUserExport = ({
  params,
  page_index,
  page_size,
  type,
}: {
  params?: Object
  page_index: number
  page_size: number
  type: 'TEACHER' | 'STUDENT'
}) => {
  return fetcher(`users/export/data?page_index=${page_index}&page_size=${page_size}&type=${type}`, {
    params: params,
  })
}

//TODO: cùng 1 page dùng 2 API -> fix lỗi
export const getUsersAPI = (query: string) => {
  return fetcher(`/users?${query}`)
}

export const getAPIUserSelect = ({
  id,
  page_index,
  page_size,
  // type,
  params,
}: {
  id: string | undefined
  page_index: number
  page_size: number
  // type?: 'TEACHER' | 'STUDENT'
  params?: Object
}) => {
  return fetcher(
    `/groups/${id}/select-users?page_index=${page_index}&page_size=${page_size}&selectType=NOT_SELECTED`,
    {
      params: params,
    }
  )
}

export const getUserGroup = (
  id: string | undefined,
  page_index: number,
  page_size: number,
  text?: string
) => {
  return fetcher(
    `/groups/${id}/users?page_index=${page_index}&page_size=${page_size}&text=${text ?? ''}`
  )
}

export const getClassCourse = async ({
  id,
  page_index,
  page_size,
  params,
}: {
  id: string
  page_index: number
  page_size: number
  params?: Object
}): Promise<IResponse<IClassCourseList>> => {
  return fetcher(`/users/${id}/class-course?page_index=${page_index}&page_size=${page_size}`, {
    params: params,
  })
}
