import { zodResolver } from '@hookform/resolvers/zod'
import { Spin } from 'antd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import CreatedContent from '../created-content/CreatedContent'
import AddTabQuiz from '../tabQuiz/addTabQuiz'
import AddTabVideo from '../tabVideo/addTabVideo'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { KTIcon } from 'src/_metronic/helpers'
import { CoursesAPI } from 'src/apis/courses'
import { PlusVideoIcon } from 'src/common/icons'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import SappLabel from 'src/components/base/label/SappLabel'
import SAPPSelect from 'src/components/base/select/SAPPSelect'
import { TYPE_DOCUMENT, VALIDATE_FILED_MAX, VALIDATION_FILED } from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { CourseCondition, ITabActivity } from 'src/type/courses'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import TabText from '../tabText/tabText'
import { z } from 'zod'
// import {IProps} from '../Tabs'

interface IProps {
  data: {
    tabId: string
    value?: any
    edit?: boolean
    creating?: boolean
  }[] //all tab
  setTabActive: React.Dispatch<React.SetStateAction<string>>
  tabActive: string
  setData: any
  closeModal: any
  course_section_id: string
  tabData: {
    tabId: string
    value?: any
    edit?: boolean
    creating?: boolean
  } //tab
  tabIndex: number
  // onlyEdit?: boolean
  courseCondition: CourseCondition
}
const TabContent = ({
  data,
  setTabActive,
  tabActive,
  setData,
  course_section_id,
  tabData,
  tabIndex,
  // onlyEdit,
  closeModal,
  courseCondition,
}: IProps) => {
  const [createDoc, setCreateDoc] = useState<{
    type?: string
    tabIndex?: number
  }>()
  const [editDoc, setEditDoc] = useState<{
    type?: string
    tabIndex?: number
    tabDocumentIndex?: number
    DocId?: string
    dataVideo?: any
  }>()
  const [loadingAddTab, setLoadingAddTab] = useState(false)
  const { idCourse } = useCourseContext()
  // const [loadingDeleteTab, setLoadingDeleteTab] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const elementRef = useRef<any>(null)
  const [isDraggable, setDraggable] = useState<boolean>(false)
  const [docItems, setDocItems] = useState<ITabActivity[]>()

  const validationSchema = z.object({
    [tabData.tabId]: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED)
      .max(1000, VALIDATE_FILED_MAX),
  })
  const useFormProp = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const { confirm, contextHolder } = useConfirm()
  const { control, setValue, handleSubmit, unregister } = useFormProp
  async function getDetailTab() {
    try {
      setLoading(true)
      const res = await CoursesAPI.getTabDetail(tabData.tabId)
      setValue(tabData.tabId, res.data.name)
      setData((prev: any) => {
        const data = [...prev]
        data[tabIndex].value.tab_name = res.data.name
        for (let j in res.data.course_tab_documents) {
          if (!data[tabIndex].value.document) {
            data[tabIndex].value.document = []
          }
          data[tabIndex].value.document[j] = { ...res.data.course_tab_documents[j] }
        }

        // handleClose()
        setLoading(false)
        return data
      })
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }
  useEffect(() => {
    if (!tabData.creating && tabActive === tabData.tabId) {
      getDetailTab()
    }
  }, [tabActive])
  // scroll den vi tri ad new doc
  useEffect(() => {
    if (createDoc?.tabIndex !== undefined && elementRef?.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    }
  }, [createDoc, elementRef?.current])
  const handleClose = (cb?: any) => {
    let body = ['Bạn có chắc chắn muốn huỷ không?']
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        setCreateDoc({})
        // setValue('editor', undefined)
        // unregister('editor')
        typeof cb === 'function' && cb()
        setEditDoc({})
      },
    })
  }
  const handleOpenAdd = (e: any, tabIndex: number) => {
    if (e === 1) {
      // setOpenAddTabText({status: true, tabIndex: tabIndex})
      setCreateDoc({ type: TYPE_DOCUMENT.TEXT, tabIndex })
    } else if (e === 2) {
      setCreateDoc({ type: TYPE_DOCUMENT.QUIZ, tabIndex })
    } else if (e === 3) {
      setCreateDoc({ type: TYPE_DOCUMENT.VIDEO, tabIndex })
    }
  }
  const handleOpenEdit = async (e: any, tabIndex: number, tabDocumentIndex: number) => {
    if (e === TYPE_DOCUMENT.TEXT) {
      // setValue('editor', data[tabIndex].value.document[indexItem].text_editor_content)
      setEditDoc({
        type: e,
        tabIndex,
        tabDocumentIndex,
        DocId: data[tabIndex].value.document[tabDocumentIndex].id,
      })
      return
    }
    if (e === TYPE_DOCUMENT.QUIZ) {
      // setValue('editor', data[tabIndex].value.document[indexItem].payload.content)
      setEditDoc({
        type: e,
        tabIndex,
        tabDocumentIndex,
        DocId: data[tabIndex].value.document[tabDocumentIndex].id,
      })
      return
    }
    if (e === TYPE_DOCUMENT.VIDEO) {
      const res = await CoursesAPI.getDocumentDetail(
        data[tabIndex].value.document[tabDocumentIndex].id
      )
      setEditDoc({
        type: e,
        tabIndex,
        tabDocumentIndex,
        DocId: data[tabIndex].value.document[tabDocumentIndex].id,
        dataVideo: res.data,
      })
      return
    }
  }
  const handleDeleteDoc = async (index: number, tabIndex: number) => {
    let body = ['Bạn có chắc chắn muốn xoá Doc này không?']
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        try {
          const docId = data[tabIndex].value.document[index].id
          await CoursesAPI.deleteDocumentTab(docId)
          const data2 = [...data]
          data2[tabIndex].value.document.splice(index, 1)
          data2[tabIndex] = {
            ...data2[tabIndex],
            value: { ...data2[tabIndex].value, document: data2[tabIndex].value.document },
          }
          setData(data2)
        } catch (err) {
          console.error(err)
        }
      },
    })
  }

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  const onSubmit = async (dataValue: any, type?: any) => {
    try {
      setLoadingSubmit(true)
      const newDoc = [...data]
      let body: any
      if (type === TYPE_DOCUMENT.QUIZ) {
        body = {
          type: createDoc?.type,
          payload: dataValue,
        }
        // newDoc[createDoc?.tabIndex || 0].value.document.push(body)
      } else if (type === TYPE_DOCUMENT.TEXT) {
        body = {
          type: createDoc?.type,
          payload: { content: dataValue.editor, attachments: [] },
          text_editor_files: textEditorFiles,
        }
      } else if (type === TYPE_DOCUMENT.VIDEO) {
        const listQuiz_ids = []
        for (let e of dataValue) {
          if (e.quiz_id && e.quiz_id !== null) {
            listQuiz_ids.push(e.quiz_id)
          }
        }
        body = {
          type: createDoc?.type,
          payload: {
            video_quiz_ids: listQuiz_ids,
          },
        }
      }

      const res = await CoursesAPI.addDocumentTab({
        ...body,
        course_id: idCourse,
        id: newDoc[createDoc?.tabIndex || 0].tabId,
      })

      if (res?.success) {
        setFileEditor([])
      }
      // if (!newDoc[createDoc?.tabIndex || 0].value.document) {
      //   newDoc[createDoc?.tabIndex || 0].value.document = []
      // }
      // // body.DocId = res.data.id
      // newDoc[createDoc?.tabIndex || 0].value.document.push(res.data)
      // setData((prev: any) => {
      //   setCreateDoc({})
      //   // setValue('editor', undefined)
      //   // unregister('editor')
      //   // typeof cb === 'function' && cb()
      //   setEditDoc({})
      //   return newDoc
      // })
      await getDetailTab()
      setCreateDoc({})
      setEditDoc({})
      toast.success('Create Doc Success!')
    } catch (err: any) {
      if (err?.response?.status === 413) {
        toast.error('Request entity too large!')
      }
    } finally {
      setLoadingSubmit(false)
    }
  }

  const onEdit = async (dataValue: any, type?: any) => {
    try {
      setLoadingSubmit(true)
      let body: any
      if (type === TYPE_DOCUMENT.QUIZ) {
        body = {
          type: type,
          payload: dataValue,
          course_id: idCourse,
        }
        // newDoc[createDoc?.tabIndex || 0].value.document.push(body)
      } else if (type === TYPE_DOCUMENT.TEXT) {
        body = {
          type: type,
          payload: {
            content: dataValue.editor,
            attachments: [],
          },
          text_editor_files: textEditorFiles,
          course_id: idCourse,
        }
      } else if (type === TYPE_DOCUMENT.VIDEO) {
        const listQuiz_ids = []
        for (let e of dataValue) {
          if (e.quiz_id && e.quiz_id !== null) {
            listQuiz_ids.push(e.quiz_id)
          }
        }
        body = {
          type: type,
          payload: {
            video_quiz_ids: listQuiz_ids,
          },
          course_id: idCourse,
        }
      }
      const res = await CoursesAPI.editDocumentTab({
        ...body,
        id: editDoc?.DocId,
      })

      if (res?.success) {
        setFileEditor([])
      }

      await getDetailTab()
      setCreateDoc({})
      setEditDoc({})
      toast.success('Edit Doc Success!')
    } catch (err: any) {
      if (err?.response?.status === 413) {
        toast.error('Request entity too large!')
      }
    } finally {
      setLoadingSubmit(false)
    }
  }
  const submitTab = async (dataValue: any, i: number, edit?: boolean) => {
    setLoadingAddTab(true)
    try {
      if (!edit) {
        const res = await CoursesAPI.addTab({
          course_section_id: course_section_id,
          tab_name: dataValue[tabActive],
          course_id: idCourse,
        })
        setData((prev: any) => {
          const data = [...prev]
          data[i].tabId = res.data.id
          data[i].creating = false
          setTabActive(res.data.id)
          setValue(res.data.id, dataValue[tabActive])
          unregister(tabActive)
          return data
        })
      } else {
        const res = await CoursesAPI.editTab({
          id: data[i].tabId,
          course_section_id: course_section_id,
          tab_name: dataValue[tabActive],
        })
        setData((prev: any) => {
          const data = [...prev]
          data[i].edit = false
          setValue(res.data.id, dataValue[tabActive])
          return data
        })
      }
    } finally {
      setLoadingAddTab(false)
    }
  }
  const handleDeleteTab = async (tabId: string, close: boolean = false) => {
    let body = [`Bạn có chắc chắn muốn ${close ? 'đóng' : 'xóa'} tab không?`]
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        // setLoadingDeleteTab(true)
        try {
          if (!tabData.creating) {
            await CoursesAPI.deleteTab(tabId)
          }
          unregister(tabId)
          const index = data.findIndex((e) => e.tabId === tabId)
          const arr = [...data]
          arr.splice(index, 1)
          setData((prev: any) => {
            return arr
          })
          setTabActive(data[index - 1]?.tabId || '')
        } catch (err) {
          console.error(err)
        } finally {
          // setLoadingDeleteTab(false)
        }
      },
    })
  }
  function titleCase(str: string) {
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    // Directly return the joined string
    return splitStr.join(' ')
  }

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('text/plain', event.currentTarget.id)
    event.dataTransfer.effectAllowed = 'move'
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>, dropId: string) => {
    event.preventDefault()
    const draggedId = event.dataTransfer.getData('text/plain')
    if (docItems?.length) {
      const dragPos = docItems?.findIndex((el: ITabActivity) => el.id === draggedId)
      const dropPos = docItems?.findIndex((el: ITabActivity) => el.id === dropId)
      let data = docItems
      let dragItem = docItems?.splice(dragPos, 1)[0]
      data?.splice(dropPos, 0, dragItem)
      setDocItems(data?.map((item, index: number) => ({ ...item, position: index + 1 })))
    }
  }

  useEffect(() => {
    setDocItems(tabData?.value?.document)
  }, [data])

  const updatePositionOfDoc = async () => {
    const documents = docItems?.map((item: ITabActivity) => ({
      document_id: item.id,
      position: item.position,
    }))
    setLoading(true)

    if (documents) {
      try {
        await CoursesAPI.updateDocPosition({ id: tabData.tabId, data: { documents } })
        toast.success('Update position of documents successfully!')
      } catch (err) {
      } finally {
        setLoading(false)
      }
    }
    setDraggable(false)
    getDetailTab()
  }

  const handleGenEdit = (tabDocumentIndex: number, tabDocumentData: any) => {
    if (
      editDoc?.tabIndex === undefined ||
      editDoc.tabIndex !== tabIndex ||
      editDoc.tabDocumentIndex !== tabDocumentIndex
    ) {
      let can_delete: boolean
      let can_edit: boolean

      if (tabDocumentData.type === TYPE_DOCUMENT.QUIZ) {
        can_delete = courseCondition.can_edit_course_content.can_delete_quiz
          ? tabDocumentData?.quiz?.state?.condition?.can_delete_quiz
          : false
        can_edit = courseCondition.can_edit_course_content.can_edit_quiz
          ? tabDocumentData?.quiz?.state?.condition?.can_edit_quiz
          : false
      } else {
        can_delete = true
        can_edit = true
      }

      return (
        <CreatedContent
          classNameContainer='sapp-h-48px mb-8 text-gray-600'
          key={tabDocumentIndex}
          {...{
            actionDelete:
              can_delete && !editDoc?.type
                ? () => {
                    handleDeleteDoc(tabDocumentIndex, tabIndex)
                  }
                : undefined,
            actionEdit:
              can_edit && !editDoc?.type
                ? () => {
                    handleOpenEdit(tabDocumentData.type, tabIndex, tabDocumentIndex)
                  }
                : undefined,
            actionPreview:
              !can_edit && !editDoc?.type
                ? () => {
                    handleOpenEdit(tabDocumentData.type, tabIndex, tabDocumentIndex)
                  }
                : undefined,
          }}
          iconGrayColor={'500'}
          name={titleCase(tabDocumentData.type)}
          disableHoverButtonBg={true}
          customConfirm
          isDraggable={isDraggable}
          onDragStart={handleDragStart}
          onDrop={(e) => handleDrop(e, tabDocumentData.id)}
          docId={tabDocumentData.id}
        />
      )
    }
    if (tabDocumentData.type === TYPE_DOCUMENT.TEXT) {
      return (
        <TabText
          onSubmit={onEdit}
          data={data[tabIndex].value.document[tabDocumentIndex].text_editor_content}
          handleClose={handleClose}
          key={tabDocumentIndex}
          loading={loadingSubmit}
        />
      )
    } else if (tabDocumentData.type === TYPE_DOCUMENT.QUIZ) {
      return (
        <AddTabQuiz
          tabId={tabData.tabId}
          doc={tabDocumentData}
          setData={setData}
          handleCancel={handleClose}
          onSubmit={(e: any) => onEdit(e, TYPE_DOCUMENT.QUIZ)}
          // edit={false}
          key={tabDocumentIndex}
        />
      )
    } else if (tabDocumentData.type === TYPE_DOCUMENT.VIDEO) {
      return (
        <AddTabVideo
          key={tabDocumentIndex}
          handleClose={handleClose}
          onSubmit={onEdit}
          data={editDoc?.dataVideo}
          loading={loading}
        />
      )
    }
  }

  return (
    <div
      key={tabData.tabId}
      className={`tab-pane fade ${
        tabActive === tabData.tabId && 'show active'
      } sapp-tab-pane-content`}
      id={`kt_tab_pane_${tabIndex}`}
      role='tabpanel'
    >
      {contextHolder}
      <Spin spinning={loading}>
        <div className='sapp-tab-content-wrapper'>
          {tabData.creating || tabData.edit ? (
            <HookFormTextField
              name={tabData.tabId}
              control={control}
              label='Tab Name'
              required
              placeholder='Tab Name'
              onChange={(e) => {
                setData((prev: any) => {
                  const data = [...prev]
                  data[tabIndex].value.tab_name = e.target.value
                  return data
                })
              }}
            />
          ) : (
            <div>
              <SappLabel label='Tab Name' required />
              {/* <span>Tab Name</span> */}
              <CreatedContent
                name={tabData.value.tab_name}
                actionEdit={() => {
                  setData((prev: any) => {
                    const data = [...prev]
                    data[tabIndex].edit = true
                    return data
                  })
                }}
                {...{ actionDelete: () => handleDeleteTab(tabActive) }}
                classNameContainer='text-gray-600 sapp-custom-card sapp-h-45px'
                // actionDelete={() => handleDeleteTab(tabActive)}
                iconGrayColor={'400'}
                fs='1'
              />
            </div>
          )}
          <>
            {tabData.creating ? (
              <div className='d-flex justify-content-end pt-4'>
                <div className='d-flex'>
                  <ButtonSecondary
                    onClick={() => {
                      handleDeleteTab(tabActive, true)
                    }}
                    // className='sapp-btn-color'
                    title='Cancel'
                    disabled={loadingAddTab}
                    size='small'
                  />
                  <ButtonPrimary
                    title='Save'
                    onClick={handleSubmit((e) => submitTab(e, tabIndex))}
                    loading={loadingAddTab}
                  />
                </div>
              </div>
            ) : tabData.edit ? (
              <div className='d-flex justify-content-end pt-4'>
                <div className='d-flex'>
                  <ButtonSecondary
                    onClick={() => {
                      setData((prev: any) => {
                        const data = [...prev]
                        data[tabIndex].edit = false
                        return data
                      })
                    }}
                    // className='sapp-btn-color'
                    title='Cancel'
                    disabled={loadingAddTab}
                    size='small'
                  />
                  <ButtonPrimary
                    title='Save'
                    onClick={handleSubmit((e) => submitTab(e, tabIndex, true))}
                    loading={loadingAddTab}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        </div>
        <div
          className={`d-flex flex-column ${
            tabData?.value?.document?.length > 0 ? '' : 'sapp-pt-24px'
          }`}
        >
          {docItems && docItems?.length > 0 && (
            <>
              <SappLabel label='List Documents' className='ps-8 mt-10' required />
              <div className='sapp-tab-content-wrapper pb-0'>
                {docItems?.map((tabDocument: ITabActivity, tabDocumentIndex: number) =>
                  handleGenEdit(tabDocumentIndex, tabDocument)
                )}
              </div>
            </>
          )}
          {createDoc?.type === TYPE_DOCUMENT.TEXT ? (
            <div
              className='sapp-tab-content-wrapper'
              ref={elementRef}
              style={{ minHeight: '600px' }}
            >
              <TabText onSubmit={onSubmit} handleClose={handleClose} loading={loadingSubmit} />
            </div>
          ) : createDoc?.type === TYPE_DOCUMENT.QUIZ ? (
            <div className='sapp-tab-content-wrapper' ref={elementRef}>
              <AddTabQuiz
                tabId={tabData.tabId}
                setData={setData}
                handleCancel={handleClose}
                onSubmit={(e: any) => onSubmit(e, TYPE_DOCUMENT.QUIZ)}
              />
            </div>
          ) : createDoc?.type === TYPE_DOCUMENT.VIDEO ? (
            <div className='sapp-tab-content-wrapper' ref={elementRef}>
              <AddTabVideo handleClose={handleClose} onSubmit={onSubmit} />
            </div>
          ) : (
            <></>
          )}
          {!editDoc?.type && !createDoc?.type && !tabData.creating && !isDraggable && (
            <div className='sapp-tab-content-wrapper pb-0'>
              <SAPPSelect
                onChange={(e) => {
                  handleOpenAdd(e, tabIndex)
                }}
                options={
                  courseCondition.can_edit_course_content.can_add_quiz
                    ? [
                        { label: 'Text', value: 1 },
                        { label: 'Quiz', value: 2 },
                        { label: 'Video', value: 3 },
                        //   {label: 'Past Exam analysis', value: 4},
                      ]
                    : [
                        { label: 'Text', value: 1 },
                        { label: 'Video', value: 3 },
                        //   {label: 'Past Exam analysis', value: 4},
                      ]
                }
                size='large'
                placeholder={
                  <div className='d-flex align-items-center gap-2 sapp-item-column sapp-text--label'>
                    <PlusVideoIcon /> Add Document
                  </div>
                }
                className='w-100 sapp-h-45px'
                suffixIcon={null}
              />
            </div>
          )}

          <div className='d-flex justify-content-between align-items-center'>
            <div>
              {!editDoc?.type && !createDoc?.type && !tabData.creating && !isDraggable && (
                <div className='ps-7 mt-8'>
                  <ButtonIcon
                    title={'Course Content'}
                    className='btn btn-sapp-icon'
                    customButton='btn-light'
                    // link={linkCancel}
                    onClick={() => closeModal()}
                  >
                    <KTIcon iconName='arrow-left' iconType='solid' className='fs-1' />
                  </ButtonIcon>
                </div>
              )}
            </div>
            {!editDoc?.type && !createDoc?.type && !tabData.creating && (
              <div className='pe-7'>
                <div
                  className={`d-flex gap-5 sapp-pt-32px sapp-px-10px sapp-pt-32px ${
                    isDraggable ? 'sapp-tab-content-wrapper' : ''
                  }`}
                >
                  {isDraggable ? (
                    <SAPPFIlterButton
                      titleReset='Cancel'
                      titleSubmit='Save'
                      okClick={updatePositionOfDoc}
                      resetClick={() => setDraggable(false)}
                      disabled={loading}
                      loading={loading}
                    />
                  ) : (
                    <ButtonPrimary title='Enable Drag' onClick={() => setDraggable(true)} />
                  )}
                  {tabData.value?.document?.length > 0 && !isDraggable && (
                    <div>
                      <ButtonSecondary
                        title='Delete Tab'
                        className='me-0'
                        onClick={() => handleDeleteTab(tabActive)}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Spin>
    </div>
  )
}
export default TabContent
