import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { CoursesAPI } from 'src/apis/courses'
import { CertificatesApi } from 'src/apis/certificates'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import TableCertificate from 'src/components/certificate/TableList'
import PageLayouts from 'src/components/layout/PageLayouts'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { LANG_CERTIFICATES, LANG_SIDEBAR } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { ITabs, Role } from 'src/type'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'
import { debounce } from 'lodash'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_CERTIFICATE_GR } from 'src/constants/permission'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: LANG_SIDEBAR.listCertificates,
  },
]
const fieldNames = ['name']
const initialValues: any = {
  name: '',
}
const CertificatePage = () => {
  const [certificateList, setCertificateList] = useState<any>()
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [courseCategory, setCourseCategory] = useState<any>()
  const searchParams = new URLSearchParams(search)
  const queryParams = {
    name: searchParams.get('name') ?? '',
    courseCategoryId: searchParams.get('courseCategoryId'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const validationSchema = z.object({
    name: z.string().optional(),
    courseCategoryId: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  const { control, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: getValues('name'),
      courseCategoryId: replaceValueAll(getValues('courseCategoryId')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    name: queryParams?.name?.trimStart().trimEnd() ?? '',
    courseCategoryId: queryParams.courseCategoryId,
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
  })
  const getParams = (
    name: string,
    courseCategoryId: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string
  ) => ({
    name,
    courseCategoryId,
    sortType,
    fromDate,
    toDate,
    dateField,
  })
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    certificateList?.topics
  )
  const fetchCertificateList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await CertificatesApi.get({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setCertificateList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const fetchCourseCategory = async ({ page_index, page_size, params }: any) => {
    try {
      const cleanedParams = {
        status: 'PUBLISH',
      }
      Object.assign(params, cleanedParams)
      const res = await CoursesAPI.getCategory({
        page_index: page_index,
        page_size: page_size,
        params: params,
      })
      return res
    } catch (error) {
      console.error(error)
    }
  }

  const handlNextCourseCategory = async ({ params }: any) => {
    const totalPages = courseCategory?.metadata?.total_pages
    const pageIndex = courseCategory?.metadata?.page_index
    const pageSize = courseCategory?.metadata?.page_size
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchCourseCategory({
        page_index: pageIndex + 1,
        page_size: pageSize,
        params: params,
      })
      const results = courseCategory.course_categories.concat(res?.data?.course_categories)
      setCourseCategory({
        metadata: res?.data?.metadata,
        course_categories: results,
      })
    }
  }

  const getCourseCategory = async ({ params }: any) => {
    const res = await fetchCourseCategory({
      page_index: 1,
      page_size: 10,
      params: { ...params, getType: 'ALL' },
    })
    if (res) {
      setCourseCategory(res?.data)
    }
  }

  const debounceCourseCategory = debounce((e) => {
    getCourseCategory({ params: { name: e } })
  }, 500)

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name')?.trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('courseCategoryId')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )

    setLoading(true)

    fetchCertificateList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
  }
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setLoading(true)
    navigate(PageLink.CERTIFICATES)
    getCourseCategory({ params: { status: 'PUBLISH' } })
    fetchCertificateList(1, 10)
  }

  useEffect(() => {
    getCourseCategory({ params: { status: 'PUBLISH' } })
  }, [])
  // const {profileMe} = useUserContext()
  // const allowRenderCreateTemplate = profileMe?.roles?.some(
  //   (role: Role) =>
  //     role.permissions?.includes(TITLE_CERTIFICATE_GR.CREATE_CERTIFICATE) ||
  //     role.code === CODE_ADMIN.SUPER_ADMIN
  // )

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.listCertificates} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='card-header border-0 pt-8'>
          <div className='w-100'>
            <div className='row'>
              {/* begin:: Search */}
              <div className='col-xl-3 col-sm-4'>
                <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
                  <HookFormTextField
                    control={control}
                    name='name'
                    placeholder='Search'
                    defaultValue={queryParams?.name}
                    onSubmit={onSubmit}
                    isListScreen
                  />
                </div>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  name='courseCategoryId'
                  control={control}
                  placeholder='Category'
                  size='large'
                  defaultValue={queryParams?.courseCategoryId ?? ''}
                  handleNextPage={(e: any) => handlNextCourseCategory({ params: { name: e } })}
                  showSearch
                  onSearch={(e: any) => {
                    if (e === undefined) {
                      return
                    }
                    debounceCourseCategory(e)
                    return
                  }}
                  classNameHeight='sapp-h-40'
                >
                  <Option key='all' value='all' className={'p-4'}>
                    All
                  </Option>
                  {courseCategory?.course_categories?.map((item: any, i: number) => {
                    return (
                      <Option key={item.id + i} value={item.id} className={'p-4'}>
                        {item?.name}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </div>
              {/* end:: Search */}

              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  name='sortType'
                  placeholder='Sort by'
                  control={control}
                  size='large'
                  defaultValue={queryParams?.sortType ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {FILTER_SELECTALL_SORTBY.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParams?.fromDate}
                  isListScreen
                />
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParams?.toDate}
                  isListScreen
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-8'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
          {/* start:: button open modal */}
          <ButtonIconPrimary
            iconName='plus'
            title={LANG_CERTIFICATES.addTemplate}
            onClick={() => navigate(PageLink.CREATE_TEMPLATE)}
            size='small'
          />
          {/* end:: button open modal */}
        </div>
        <TableCertificate
          certificateList={certificateList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchCertificateList={fetchCertificateList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(CertificatePage)
