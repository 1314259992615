import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { TYPE_DOCUMENT, VALIDATION_FILED } from 'src/constants'
import { useForm } from 'react-hook-form'
import { Dispatch, SetStateAction, useLayoutEffect, useState } from 'react'
import './tabText.scss'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import { useParams } from 'react-router-dom'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

interface IProps {
  onSubmit: any
  handleClose: any
  // onEdit:any
  // edit?: boolean
  data?: any
  loading?: boolean
  setFiles?: Dispatch<SetStateAction<any>>
  // tabId?: string | null | undefined
}
const TabText = ({ onSubmit, handleClose, data, loading, setFiles }: IProps) => {
  const params = useParams()

  const validationSchema = z.object({
    editor: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
  })
  const useFormProp = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const [defaultEditor, setDefaultEditor] = useState<any>()
  const { control, setValue, handleSubmit, unregister } = useFormProp
  useLayoutEffect(() => {
    if (data) {
      setValue('editor', data)
      setDefaultEditor(data)
    }
  }, [data])
  return (
    <div className='border-solid rounded border border-gray-300 sapp-py-24px'>
      <div className='d-flex justify-content-between  align-items-start sapp-px-24px border-bottom'>
        <div className='d-flex'>
          <div
            className={`bg-blue-500 text-gray-800 pb-7 focus:outline-none sapp-fs-14 fw-semibold sapp-lh-16px`}
            style={{
              borderBottom: '1px solid #FFB800',
              borderTop: '0',
              borderLeft: '0',
              borderRight: '0',
              background: 'none',
            }}
          >
            New Text Document
          </div>
        </div>
        {/* <div className='d-flex gap-2 justify-content-center align-items-center'></div> */}
      </div>
      <div className='sapp-p-24px'>
        <HookFormEditor
          math
          name={'editor'}
          control={control}
          defaultValue={defaultEditor}
          label='Mô tả'
          required
          resourceLocation={RESOURCE_LOCATION.COURSE}
          object_id={params?.id}
          setDataFile={setFiles && setFiles}
        />
      </div>
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex justify-content-end sapp-px-24px'>
          <ButtonSecondary
            title='Cancel'
            className='me-5'
            onClick={() => handleClose(() => unregister('editor'))}
          />
          <ButtonPrimary
            onClick={handleSubmit((e) => onSubmit(e, TYPE_DOCUMENT.TEXT))}
            // className='btn btn-active-primary'
            title='Save Document'
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}
export default TabText
