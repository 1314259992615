import { useLocation, useParams } from 'react-router-dom'
import { CLASS_PROFILE_URL } from 'src/constants/classes'
import ExamOverview from './ExamOverview'
import ExamSetting from './ExamSetting'

const ExamTabs = () => {
  const location = useLocation()
  const { id } = useParams()

  const renderExam = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case CLASS_PROFILE_URL.OVERVIEW:
        return <ExamOverview />
      case CLASS_PROFILE_URL.SETTING:
        return <ExamSetting />

      default:
        return <ExamOverview />
    }
  }
  return <>{renderExam()}</>
}

export default ExamTabs
