import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { isUndefined } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import { uploadFile, UsersAPI } from 'src/apis/user'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import HeaderTab from 'src/components/base/HeaderTab'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import { IUpdateTeacher } from 'src/components/teacher/TeacherPostForm/TeacherPostForm'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import ModalEditEmail from 'src/components/user-list/components/user-edit-modal/ModalEditEmail'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  STATUS_FORM,
  TEACHER_PROFILE,
} from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM, LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { teachingStatusArray } from 'src/constants/teacher'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import useStaffs from 'src/hooks/useStaffs'
import { EStatusUser, IError, Role } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import { ITeacher } from 'src/type/teacher'
import { sizeInBytes } from 'src/utils'
import { z } from 'zod'
import { DEFAULT_SCHEMA } from '../../schema'
import Subjects from '../../TeacherPostForm/Subjects'
const { Option } = Select

interface IProps {
  teacherDetail: IStudentDetail | undefined
  setTeacherDetail: Dispatch<SetStateAction<any>>
  loading: boolean
}

const EditProfileTeacher = ({ teacherDetail, setTeacherDetail }: IProps) => {
  const navigate = useNavigate()

  const [defaultValues, setDefaultValues] = useState({
    id: teacherDetail?.id || '',
    username: teacherDetail?.username,
    staff_ids: teacherDetail?.user_staff_instances?.map((item) => item.staff_id) || [],
    phone: teacherDetail?.user_contacts?.find((user) => user.is_default)?.phone,
    full_name: teacherDetail?.detail?.full_name,
    email: teacherDetail?.user_contacts?.find((user) => user.is_default)?.email,
    status: teacherDetail?.status,
    sex: teacherDetail?.detail.sex,
    job_title: teacherDetail?.detail.job_title,
    facebook: teacherDetail?.detail.facebook,
    linkedin: teacherDetail?.detail.linkedin,
    youtube: teacherDetail?.detail.youtube,
    describe: teacherDetail?.detail.describe,
    teacher_status: teacherDetail?.teacher_status,
    category_primary_id: teacherDetail?.category_primary_id,
  })
  const [isActive, setIsActive] = useState(false)
  const { id } = useParams()
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const { confirm, contextHolder } = useConfirm()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const allowRenderEditEmail = profileMe?.roles?.some((role: Role) => {
    return (
      hasPermission(role, TITLE_GR.PUT_CHANGE_EMAIL_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
    )
  })
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${TEACHER_PROFILE}/${id}/overview`),
    })
  }

  const schema = z.object(DEFAULT_SCHEMA)

  const { control, handleSubmit, setValue, setError, watch } = useForm<ITeacher>({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    defaultValues: defaultValues as any,
  })

  const isValidHttpUrl = (url: string) => {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    return pattern.test(url)
  }

  const onSubmit = async (data: ITeacher) => {
    setLoadingSubmit(true)
    const {
      email,
      full_name,
      phone,
      username,
      status,
      avatar,
      job_title,
      sex,
      facebook,
      youtube,
      linkedin,
      describe,
      teacher_teachable_instances,
    } = data

    if (facebook && !isValidHttpUrl(facebook)) {
      setError('facebook', { message: 'Invalid Value' })
      return
    }

    if (linkedin && !isValidHttpUrl(linkedin)) {
      setError('linkedin', { message: 'Invalid Value' })
      return
    }

    if (youtube && !isValidHttpUrl(youtube)) {
      setError('youtube', { message: 'Invalid Value' })
      return
    }
    // Prevent form submission
    if (!isUndefined(avatar) && (avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE))
      return

    if (!isUndefined(avatar)) {
      await uploadFile({ userId: teacherDetail?.id, avatar: avatar })
    }

    try {
      const paramsUpdate: IUpdateTeacher = {
        ...data,
        email: email.toLowerCase(),
        full_name: full_name,
        phone: phone,
        username: username,
        status: status,
        sex: sex || null,
        avatar: null,
        job_title,
        facebook,
        youtube,
        linkedin,
        describe,
        teacher_teachable_instances: teacher_teachable_instances?.filter(
          (item) => item.category_id && item.category_id !== ''
        ),
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      await UsersAPI.update({
        id: teacherDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update SuccessFully!')
          navigate(`${TEACHER_PROFILE}/${teacherDetail?.id}/overview`)
        })
        .catch((err: any) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, { message: errorMessage })
          }, {})
        })
    } catch (error) {
      setLoadingSubmit(false)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const { staffs, hasNextPage, fetchNextPage } = useStaffs({
    status: EStatusUser.ACTIVE,
  })

  useEffect(() => {
    if (!teacherDetail?.id) return
    setDefaultValues({
      id: teacherDetail?.id || '',
      username: teacherDetail?.username,
      staff_ids: teacherDetail?.user_staff_instances?.map((item) => item.staff_id) || [],
      phone: teacherDetail?.user_contacts?.find((user) => user.is_default)?.phone,
      full_name: teacherDetail?.detail?.full_name,
      email: teacherDetail?.user_contacts?.find((user) => user.is_default)?.email,
      status: teacherDetail?.status,
      sex: teacherDetail?.detail.sex,
      // avatar: z.any(),
      job_title: teacherDetail?.detail.job_title,
      facebook: teacherDetail?.detail.facebook,
      linkedin: teacherDetail?.detail.linkedin,
      youtube: teacherDetail?.detail.youtube,
      describe: teacherDetail?.detail.describe,
      teacher_status: teacherDetail?.teacher_status,
      category_primary_id: teacherDetail?.category_primary_id,
    })
  }, [teacherDetail])

  return (
    <>
      {contextHolder}
      {teacherDetail && (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab title='Edit Teacher' />

          <div className='collapse show'>
            <div className='form'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-semibold fs-6'>Avatar</label>
                  <div className='col-lg-9'>
                    <SAPPHookUploadFile
                      name='avatar'
                      control={control}
                      setValue={setValue}
                      setError={setError}
                      imagePreview={
                        teacherDetail?.detail?.avatar?.['150x150'] ??
                        teacherDetail?.detail?.avatar?.ORIGIN ??
                        avatar
                      }
                      accept={ACCEPT_UPLOAD_MIME_TYPE}
                      maxFileSize={DEFAULT_MAX_FILE_SIZE}
                      setShowAvatarDefault={setShowAvatarDefault}
                      removeAvatar={avatar}
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    {LANG_PLACEHOLDER.FULLNAME}
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='full_name'
                      control={control}
                      placeholder={LANG_PLACEHOLDER.FULLNAME}
                      required
                      guideline={GUIDELINE_FULLNAME}
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    Username
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='username'
                      control={control}
                      placeholder='Username'
                      disabled
                      required
                      guideline={GUIDELINE_USERNAME}
                    />
                  </div>
                </div>

                <div className='row mb-md-6 mb-0'>
                  <label className='col-xl-3 col-lg-3 col-form-label required fw-semibold fs-6'>
                    Email
                  </label>

                  <div className='col-xl-8 col-lg-7 col-md-10 fv-row'>
                    <HookFormTextField
                      name='email'
                      control={control}
                      placeholder='Email'
                      required
                      disabled
                      guideline={GUIDELINE_EMAIL}
                    />
                  </div>
                  <div className='col-xl-1 col-lg-2 col-md-2 d-flex justify-content-end mt-md-0 mt-3'>
                    <ButtonPrimary
                      title='Edit'
                      disabled={!allowRenderEditEmail}
                      className='sapp-p-button--custom'
                      onClick={() => setOpenEditEmail(true)}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Phone</label>

                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='phone'
                      control={control}
                      placeholder='Phone Number'
                      required
                      disabled
                      guideline={GUIDELINE_PHONE}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    Status
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <HookFormSelectAntd
                      name='status'
                      control={control}
                      classNameHeight='sapp-h-45px'
                      disabled={!allowRenderEdit}
                    >
                      {STATUS_FORM.map((status) => (
                        <Option key={status.label} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-semibold fs-6'>Gender</label>

                  <div className='col-lg-9 fv-row'>
                    <HookFormSelectAntd
                      name='sex'
                      control={control}
                      classNameHeight='sapp-h-45px'
                      placeholder='Please select'
                      disabled={!allowRenderEdit}
                    >
                      {GENDER_FORM.map((status) => (
                        <Option key={status.label} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='Job Title' className='col-lg-3' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='job_title'
                      control={control}
                      placeholder='Job Title'
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    Teaching Status
                  </label>
                  <div className='col-lg-9'>
                    <HookFormSelectAntd
                      size='large'
                      control={control}
                      name='teacher_status'
                      required
                      showSearch
                      classNameHeight='sapp-h-40'
                    >
                      {teachingStatusArray.map((status) => (
                        <Option key={status.value} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    Person in Charge
                  </label>
                  <div className='col-lg-9'>
                    <HookFormSelectMultiple
                      control={control}
                      name='staff_ids'
                      placeholder='Person in Charge'
                      required
                      handleNextPage={() => {
                        hasNextPage && fetchNextPage()
                      }}
                      selectOptions={
                        staffs
                          ? staffs?.map((staff) => ({ name: staff.username, value: staff.id }))
                          : []
                      }
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='Facebook Link' className='col-lg-3' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='facebook'
                      control={control}
                      placeholder='Facebook Link'
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='Youtube Link' className='col-lg-3' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='youtube'
                      control={control}
                      placeholder='Youtube Link'
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='LinkedIn' className='col-lg-3' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='linkedin'
                      control={control}
                      placeholder='LinkedIn'
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='Describe' className='col-lg-3' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormEditor
                      defaultValue={teacherDetail?.detail.describe}
                      control={control}
                      name='describe'
                      placeholder=''
                      resourceLocation={RESOURCE_LOCATION.COURSE_SECTION}
                      object_id={''}
                      setDataFile={() => {}}
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='Teaching Programs' className='col-lg-3' />
                  <div className='col-lg-9'>
                    <Subjects
                      control={control}
                      setValue={setValue}
                      setError={setError}
                      watch={watch}
                      teacherDetail={teacherDetail}
                    />
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <ButtonSecondary title='Discard' className='me-2' onClick={hanleCancel} />
                <ButtonPrimary
                  title='Save Changes'
                  onClick={handleSubmit(onSubmit)}
                  loading={loadingSubmit}
                  // disabled={!allowRenderEdit}
                />
              </div>
            </div>
          </div>
          <ModalEditEmail
            open={openEditEmail}
            setOpen={setOpenEditEmail}
            isActive={isActive}
            setIsActive={setIsActive}
            currentEmail={defaultValues.email}
            setTeacherDetail={setTeacherDetail}
          />
        </div>
      )}
    </>
  )
}

export default EditProfileTeacher
