import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'

import toast from 'react-hot-toast'
import { ProductAPI } from 'src/apis/short-course/product'
import PageLayouts from 'src/components/layout/PageLayouts'
import CreateAndUpdateContent from 'src/components/shop/products/CreateAndUpdateContent'
import CreateAndUpdateSetting from 'src/components/shop/products/CreateAndUpdateSetting'
import { PageLink, VALIDATE_NUMBER, VALIDATION_FILED } from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import useShopFilter from 'src/hooks/use-shop-filter'
import { RobotMeta } from 'src/type/shop/common'
import { FAQuestionList, IProductForm } from 'src/type/shop/product'
import { metaValidationSchema } from 'src/utils/shop/validation'
import { IStep } from '../faqs/FaqDetail'
import { isNull, isUndefined } from 'lodash'

const ProductDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [newStep, setNewStep] = useState<any>([
    {
      title: 'Content',
      status: 'current',
      link: '',
    },
    {
      title: 'Setting',
      status: '',
      link: '',
    },
  ])

  const validationSchema = z.object({
    title: z.string().trim().min(1, VALIDATION_FILED),
    content: z.string().trim().nullable().optional(),
    thumbnail: z.any(),
    video_trailer: z.any(),
    product_category_ids: z.array(z.string()).optional().default([]),
    primary_product_category_id: z.string().trim().min(1, VALIDATION_FILED),
    promotion_ration: z
      .string()
      .regex(/^[0-9]*$/, VALIDATE_NUMBER)
      .transform((val) => parseInt(val))
      .refine((val) => val >= 0, { message: 'Only Number' })
      .optional(),
    promotion_duration: z
      .string()
      .trim()
      .regex(/^[0-9]*$/, VALIDATE_NUMBER)
      .transform((val) => parseInt(val))
      .refine((val) => val >= 0, { message: 'Only Number' })
      .optional(),
    suffix_url: z.string().nullable().optional(),
    meta_title: z.string().min(1, VALIDATION_FILED),
    origin_price: z
      .string()
      .trim()
      .min(1, VALIDATION_FILED)
      .transform((val) => parseInt(val)),
    related_production_ids: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
        })
      )
      .transform((val) => {
        return val?.map((item) => item.id)
      })
      .default([]),
    meta_description: z.string().min(1, VALIDATION_FILED),
    focus_keyword: z.string().trim().nullable().optional(),
    robot_meta: metaValidationSchema,
    canonical_url: z.string().trim().optional(),
    status: z.string().trim().min(1, VALIDATION_FILED),
    faq_questions: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
        })
      )
      .default([]),
    main_class_id: z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .transform((val) => {
        return val?.id
      }),
    trial_class_id: z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .transform((val) => {
        return val.id
      }),
  })
  const { confirm, contextHolder } = useConfirm()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        navigate(PageLink.PRODUCTS)
      },
    })
  }

  const ProductForm = useForm<IProductForm>({
    mode: 'onSubmit',
    resolver: zodResolver(validationSchema),
  })

  const { handleSubmit, setValue, trigger, setError, clearErrors, getValues } = ProductForm

  const loadData = async () => {
    if (id && id !== 'undefined') {
      const res = await ProductAPI.getProduct(id)
      const data = res.data
      setValue('title', data.title ?? '')
      setValue('content', data.content ?? '')
      setValue('main_class_id', data.main_class)
      setValue('trial_class_id', data.trial_class)
      setValue('origin_price', data?.origin_price?.toString() ?? '0')
      setValue('promotion_ration', data?.promotion_ration?.toString() ?? '0')
      setValue('promotion_duration', data?.promotion_duration?.toString() ?? '0')
      setValue(
        'product_category_ids',
        data?.shop_product_categories?.map((item: { id: string }) => item.id)
      )
      setValue(
        'faq_questions',
        data?.shop_faq_questions?.map((item: FAQuestionList) => ({ id: item.id, name: item.title }))
      )
      setValue('suffix_url', data?.suffix_url ?? '')
      setValue('meta_title', data?.meta_title ?? '')
      setValue('meta_description', data.meta_description ?? '')
      setValue('focus_keyword', data?.focus_keyword ?? '')
      setValue('canonical_url', data?.canonical_url ?? '')
      setValue('primary_product_category_id', data?.primary_product_category_id)
      setValue(
        'related_production_ids',
        data?.relatedProducts?.map((item: { id: string; title: string }) => ({
          id: item.id,
          name: item.title,
        }))
      )
      setValue('status', data.status)
      setValue('thumbnail', data?.thumbnail?.ORIGIN ?? null)
      setValue('video_trailer', data?.video_trailer?.sub_url)
      const robotMeta =
        data?.robot_meta && typeof data?.robot_meta === 'string' ? JSON.parse(data?.robot_meta) : ''
      Object.keys(robotMeta).forEach((key: string) => {
        setValue(`robot_meta.${key as keyof RobotMeta}`, robotMeta[key])
      })
    }
  }

  const categoryFilter = useShopFilter({
    callback: ProductAPI.getProducttCategories,
    type: 'product',
    key: 'productCategories',
  })

  const { debounceGetData: debounceGetCategories } = categoryFilter

  const uploadVideo = async (videoTrailer: File, id: string) => {
    if (videoTrailer) {
      const metaVideo = await ProductAPI.preUploadVideo({
        name: videoTrailer?.name,
        size: videoTrailer?.size,
        content_type: videoTrailer?.type,
      })
      await ProductAPI.uploadVideo({
        contentType: videoTrailer?.type,
        file_key: metaVideo.data?.file_key,
        upload_url: metaVideo.data?.upload_url,
        blob: videoTrailer,
        type: metaVideo.data?.type,
      })
      if (id) {
        await ProductAPI.addResource(id, {
          name: 'shortcourse/product',
          file_key: metaVideo.data.file_key,
          size: videoTrailer?.size,
        })
      }
    }
  }

  const isValidThumnail = () => {
    const thumbnail = getValues('thumbnail')
    const video_trailer = getValues('video_trailer')
    if (isNull(thumbnail) || isUndefined(thumbnail)) {
      setError('thumbnail', { message: VALIDATION_FILED })
      return false
    } else {
      clearErrors('thumbnail')
    }
    if (isNull(video_trailer) || isUndefined(video_trailer)) {
      setError('video_trailer', { message: VALIDATION_FILED })
      return false
    } else {
      clearErrors('video_trailer')
    }
    return true
  }

  const onSubmit = async (data: IProductForm) => {
    if (!isValidThumnail()) return
    let thumbnail = data.thumbnail
    let videoTrailer = data.video_trailer
    delete data.thumbnail
    delete data.video_trailer
    if (!data?.primary_product_category_id?.trim()) {
      delete data.primary_product_category_id
    }
    const result = { ...data, question_ids: data?.faq_questions?.map((item) => item.id) ?? [] }
    try {
      if (id && id !== 'undefined') {
        const res = await ProductAPI.updateProduct({ id: id, data: result })
        if (thumbnail && typeof thumbnail !== 'string') {
          await ProductAPI.uploadThumnail({ id: res?.data?.id, thumbnail })
        }
        if (videoTrailer && typeof videoTrailer !== 'string') {
          videoTrailer && uploadVideo(videoTrailer, res?.data?.id)
        }
        toast.success('Update Product Successfully!')
      } else {
        const res = await ProductAPI.createProdcut({ data: result })
        if (res?.data?.id) {
          if (thumbnail && typeof thumbnail !== 'string') {
            await ProductAPI.uploadThumnail({ id: res?.data?.id, thumbnail })
          }

          if (res?.data?.id && videoTrailer && typeof videoTrailer !== 'string') {
            videoTrailer && uploadVideo(videoTrailer, res?.data?.id)
          }
        }
        toast.success('Create Product Successfully!')
      }
      navigate(PageLink.PRODUCTS)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    debounceGetCategories()
    loadData().catch((error) => console.error)
  }, [id])

  const handleNextStep = async () => {
    if (newStep[newStep.length - 1].status === 'current') {
      navigate(PageLink.SHOP_EVENT)
    } else {
      const isValid = await trigger(['title', 'main_class_id', 'trial_class_id', 'origin_price'])

      if (!isValid || !isValidThumnail()) return
      setNewStep((prev: IStep[]) => {
        const indexItem = prev.findIndex((item) => item.status === 'current') + 1
        return prev.map((step: IStep, index: number) => ({
          ...step,
          status:
            index < indexItem
              ? 'active'
              : index === indexItem && index <= prev.length - 1
              ? 'current'
              : '',
        }))
      })
    }
  }

  return (
    <PageLayouts
      titleTab='New Product'
      pageTitle=''
      breadcrumbs={[]}
      showSideBar={false}
      classNameLayout='pt-0'
      showFooter={false}
      backgroudColor='bg-white'
    >
      {contextHolder}
      <div className='sapp-mw-1200px mx-auto px-2'>
        <div className='pb-0'>
          {newStep[0].status === 'current' && (
            <CreateAndUpdateContent
              useFormProp={ProductForm}
              onNextStep={handleNextStep}
              step={newStep}
              setStep={setNewStep}
              trigger={trigger}
            />
          )}
          {newStep[1].status === 'current' && (
            <CreateAndUpdateSetting
              categoryFilter={categoryFilter}
              loading={loading}
              useFormProp={ProductForm}
              handleSubmit={handleSubmit(onSubmit)}
              onCancel={handleCancel}
              step={newStep}
              setStep={setNewStep}
            />
          )}
        </div>
      </div>
    </PageLayouts>
  )
}

export default ProductDetail
