import React, { Dispatch, useEffect, useState } from 'react'
import './modalVideoQuestion.scss'
// import TimePicker from 'src/components/base/time-picker'
import toast from 'react-hot-toast'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { useConfirm } from 'src/hooks/use-confirm'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import SappModal from 'src/components/base/SappModal'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import { Select } from 'antd'
import {
  IQuestionTagList,
  IResponseQuestionMeta,
} from 'src/components/question-bank/shared/interfaces'
import { IQuestion } from 'preview-activity/dist/shared/interfaces'
import { LIST_TYPE } from 'src/components/courses/shared/Interfaces'
import { QuestionBankAPI } from 'src/apis/question-bank'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { replaceValueAll } from 'src/utils/string'
import { ISearchCourse } from 'src/hooks/use-course-filter'
import { CoursesAPI } from 'src/apis/courses'
import { DEFAULT_SELECT_ALL, FILTER_SELECTALL_SORTBY } from 'src/constants'
import TableListQuestion from 'src/components/courses/test/TableListQuestion'
import { debounce, isUndefined } from 'lodash'
import { ITopicList, ITopicObject } from 'src/type/question-bank'
const { Option } = Select

interface IProp {
  open: any
  onClose: () => void
  player: any
  setListTimeLine: Dispatch<React.SetStateAction<any[]>>
  setSortedListTimeLine?: Dispatch<React.SetStateAction<any[]>>
  quiz_id?: string
  isPreventDependentQuestion?: boolean
}
const ChooseQuestionModal = ({
  open,
  onClose,
  player,
  setListTimeLine,
  setSortedListTimeLine,
  quiz_id,
  isPreventDependentQuestion = false,
}: IProp) => {
  const defaultValueMeta = {
    page_index: 0,
    page_size: 0,
    total_pages: 0,
    total_records: 0,
  }
  const { confirm, contextHolder } = useConfirm()
  // const {
  //   state: {multiple},
  //   getListQuestions,
  // } = useQuestionProviderContext()
  const [questionState, setQuestionState] = useState<IResponseQuestionMeta<IQuestion[]>>()
  const [loading, setLoading] = useState(false)
  const [courseList, setCourseList] = useState<any>({
    courses: [],
    metadata: defaultValueMeta,
  })
  const [listSection, setListSection] = useState<any>([])
  const [searchCourse, setSearchCourse] = useState('')
  // const [generatedParams, setGeneratedParams] = useState<any>()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [loadingCourse, setLoadingCourse] = useState<boolean>(false)
  const [listItemSet, setListItemSet] = useState<any>({
    topics: [],
    meta: defaultValueMeta,
  })

  const [questionTags, setQuestionTags] = useState<any>({
    question_tags: [],
    metadata: defaultValueMeta,
  })

  useEffect(() => {
    if (open.state) {
      getListQuestionFilter({ page_index: 1, page_size: 10 })
    }
  }, [open])

  //set old data for edit question item
  useEffect(() => {
    if (open.data) {
      setSelectedRows([open.data.questionId])
    }
  }, [open.data])
  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy chọn không?'],
      onClick: () => {
        setSelectedRows([])
        reset()
        // setLoading(true)
        clearChildren('course')
        // handleResetFilter()
        onClose()
      },
    })
  }
  const getParams = (
    question_content: string,
    course_id: string,
    part_id: string,
    chapter_id: string,
    unit_id: string,
    course_section_id: string,
    filter_type: string,
    sortType: string,
    quiz_id?: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string,
    topic_id?: string,
    tag_id?: string
  ) => ({
    question_content,
    course_id,
    part_id,
    chapter_id,
    unit_id,
    course_section_id,
    filter_type,
    sortType,
    quiz_id,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
    topic_id,
    tag_id,
  })
  const validationSchema = z.object({
    question_content: z.string().optional(),
    course_id: z.string().optional(),
    part: z.string().optional(),
    chapter: z.string().optional(),
    unit: z.string().optional(),
    activity: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    quiz_id: z.any(),
  })
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    getListQuestionFilter({ page_index: 1, page_size: 10 })
  }
  const getListQuestionFilter = ({
    page_index,
    page_size,
  }: {
    page_index: number
    page_size: number
  }) => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))

    const showSearchParams = {
      course_id: getValues('course_id'),
      part: getValues('part'),
      chapter: getValues('chapter'),
      unit: getValues('unit'),
      activity: getValues('activity'),
      topic_id: getValues('item-set'),
      tag_id: getValues('tag'),
    }

    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('question_content').trimStart().trimEnd() ?? '',
        replaceValueAll(showSearchParams.course_id),
        replaceValueAll(showSearchParams.part),
        replaceValueAll(showSearchParams.chapter),
        replaceValueAll(showSearchParams.unit),
        replaceValueAll(showSearchParams.activity),
        replaceValueAll('ALL'),
        replaceValueAll(getValues('sortType')),
        replaceValueAll(getValues('quiz_id') || ''),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at',
        replaceValueAll(showSearchParams.topic_id),
        replaceValueAll(showSearchParams.tag_id)
      )
    )
    // setGeneratedParams(cleanedParams)
    getListQuestions('multiple', page_index || 1, page_size || 10, cleanedParams)
  }
  const getListQuestions = (
    type: LIST_TYPE | boolean = 'all',
    page_index?: number,
    page_size?: number,
    param?: any
  ) => {
    if (typeof type === 'string') {
      setLoading(true)
      ;(async () => {
        const params = {
          ...param,
          page_index,
          page_size,
        }

        if (isPreventDependentQuestion) {
          params['is_independent_question'] = !isPreventDependentQuestion
        }

        try {
          const response = await QuestionBankAPI.getQuestions(
            {
              params,
            },
            type
          )
          setQuestionState(response as any)
        } catch (e) {
        } finally {
          setLoading(false)
        }
      })()
    }
  }

  const getListItemSet = async (page_index?: number, page_size?: number, param?: Object) => {
    try {
      const response = await QuestionBankAPI.getTopic({
        page_index: page_index ?? 1,
        page_size: page_size ?? 20,
        params: param,
      })
      setListItemSet((prev: ITopicList) => {
        return {
          meta: response.data.meta,
          topics: [...prev.topics, ...response.data.topics].filter(
            (item, index, self) => index === self.findIndex((t) => t.id === item.id)
          ),
        }
      })
    } catch (error) {}
  }

  const getListQuestionTags = async (page_index?: number, page_size?: number, param?: Object) => {
    try {
      const response = await QuestionBankAPI.getQuestionTags({
        page_index: page_index ?? 1,
        page_size: page_size ?? 10,
        params: param,
      })

      setQuestionTags((prev: IQuestionTagList) => {
        return {
          metadata: response.data.metadata,
          question_tags: [...prev.question_tags, ...response.data.question_tags].filter(
            (item, index, self) => index === self.findIndex((t) => t.id === item.id)
          ),
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleNextPageItemSet = (params: Object) => {
    const total_pages = listItemSet?.meta?.total_pages
    const page_index = listItemSet?.meta?.page_index
    const page_size = listItemSet?.meta?.page_size
    if (total_pages) {
      if (page_index < total_pages) {
        if (!loadingCourse) {
          getListItemSet(page_index + 1, page_size, params)
        }
      }
    }
  }

  const handleNextPageITag = (params: Object) => {
    const total_pages = questionTags?.metadata?.total_pages
    const page_index = questionTags?.metadata?.page_index
    const page_size = questionTags?.metadata?.page_size
    if (total_pages) {
      if (page_index < total_pages) {
        if (!loadingCourse) {
          getListQuestionTags(page_index + 1, page_size, params)
        }
      }
    }
  }

  const debounceSearchItemSet = debounce((e) => {
    getListItemSet(1, 10, { name: e })
  })

  const debounceSearchTag = debounce((e) => {
    getListQuestionTags(1, 10, { name: e })
  })

  const handleSave = () => {
    if (selectedRows[0]) {
      const menuName = questionState?.data.questions.find(
        (e) => e.id === selectedRows[0]
      )?.question_content
      //if editing, set both sortedListTimeLine and listTimeLine
      if (open.edit && setSortedListTimeLine) {
        setSortedListTimeLine((prev) => {
          const oldTimeLines = [...prev]
          const oldTimeLineIndex = open.index
          oldTimeLines[oldTimeLineIndex] = {
            ...oldTimeLines[oldTimeLineIndex],
            questionId: selectedRows[0],
            menuName: menuName,
          }
          setSelectedRows([])
          onClose()
          setListTimeLine(oldTimeLines)
          reset()
          // setLoading(true)
          clearChildren('course')
          return oldTimeLines
        })
      } else {
        setListTimeLine((prev) => {
          const arr = [...prev]
          arr.push({
            time: Math.floor(player.currentTime),
            questionId: selectedRows[0],
            menuName: menuName,
          })
          setSelectedRows([])
          onClose()
          reset()
          // setLoading(true)
          clearChildren('course')
          return arr
        })
      }
    } else toast.error('You have not selected any question!')
  }
  const toggleRowSelection = (id: string) => {
    // handleErr('questionId', id)
    setSelectedRows([id])
  }
  const queryParams = {
    question_content: '',
    course_id: '',
    part: '',
    chapter: '',
    unit: '',
    course_section_id: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
    tag_id: '',
    topic_id: '',
  }
  const newCourse = courseList?.courses?.map((e: any) => ({
    label: e.name,
    value: e.id,
  }))
  const clearChildren = (type: keyof ISearchCourse, reset: boolean = true): any => {
    const children = {
      course: ['part', 'chapter', 'unit', 'activity'],
      part: ['chapter', 'unit', 'activity'],
      chapter: ['unit', 'activity'],
      unit: ['activity'],
    }
    const key = type as keyof typeof children
    let newListCourse = { ...listSection }
    if (type !== 'activity') {
      for (let child of children[key]) {
        const newSetValue = setValue as any
        newSetValue(child, '')
        newListCourse = {
          ...newListCourse,
          [child]: {
            loading: false,
            data: [],
            id: undefined,
          },
        }
      }
      setListSection(newListCourse)
    }
  }
  const getCourseList = async ({
    name,
    page_index = 1,
    page_size = 20,
  }: {
    name?: string
    page_index?: number
    page_size?: number
  }) => {
    try {
      setLoadingCourse(true)
      const res = await CoursesAPI.get({
        page_index: page_index,
        page_size: page_size,
        params: { name: name },
      })
      if (page_index === 1) {
        setCourseList(res.data)
      } else {
        setCourseList((prev: any) => {
          const obj = { ...prev }
          const newArr = obj.courses.concat(res.data.courses)
          obj.courses = newArr
          obj.metadata = { ...res.data.metadata }
          return obj
        })
      }
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingCourse(false)
    }
  }
  const handleNextPageCourse = () => {
    const total_pages = courseList?.metadata?.total_pages
    const page_index = courseList?.metadata?.page_index
    const page_size = courseList?.metadata?.page_size
    if (total_pages) {
      if (page_index < total_pages) {
        if (!loadingCourse) {
          getCourseList({
            name: searchCourse,
            page_index: page_index + 1,
            page_size: page_size,
          })
        }
      }
    }
  }
  useEffect(() => {
    async function fetch() {
      getCourseList({})
    }
    fetch()
  }, [])
  const debounceSearchCourse = debounce((e) => {
    getCourseList({ name: e, page_index: 1, page_size: 20 })
    setSearchCourse(e)
  }, 500)
  const getListSection = async ({
    type,
    name,
    parentId,
    page_index = 1,
    page_size = 50,
  }: {
    type: keyof ISearchCourse
    name?: string
    parentId?: string
    page_index?: number
    page_size?: number
  }) => {
    try {
      const response = await CoursesAPI.getListSort({
        name,
        parentId: type !== 'part' ? parentId : undefined,
        courseId: type === 'part' ? parentId : undefined,
        page_index: page_index,
        page_size: page_size,
        type: type.toUpperCase(),
      })
      setListSection((e: any) => {
        const obj = { ...[type] } as any
        let newData = []
        if (obj.data) {
          newData = obj.data.concat(response?.data?.sections)
        } else {
          newData = response?.data?.sections
        }
        return {
          ...e,
          [type]: {
            loading: false,
            data: newData || [],
            metadata: response?.data?.meta,
          },
        }
      })
    } catch (error) {}
  }
  const fieldNames = [
    'search_key',
    'course',
    'part',
    'chapter',
    'unit',
    'activity',
    'sortType',
    'fromDate',
    'toDate',
  ]
  const initialValues: any = {
    search_key: '',
    course: '',
    part: '',
    chapter: '',
    unit: '',
    sortType: '',
    activity: '',
    fromDate: '',
    toDate: '',
    quiz_id: '',
  }
  const handleResetFilter = () => {
    reset()
    // setLoading(true)
    clearChildren('course')
    // toggleCheckAll(false)
    fieldNames.forEach((fieldName: any) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setSelectedRows([])
    // setGeneratedParams(undefined)
    getListQuestions('multiple', 1, queryParams.page_size || 10, {
      filter_type: 'ALL',
      dateField: 'updated_at',
    })
  }

  const handlNextPageSection = ({ type, name }: { type: keyof ISearchCourse; name?: string }) => {
    const total_pages = listSection?.[type]?.metadata?.total_pages
    const page_index = listSection?.[type]?.metadata?.page_index
    const page_size = listSection?.[type]?.metadata?.page_size
    const parentId =
      listSection?.[type]?.data?.[0]?.course_id || listSection?.[type]?.data?.[0]?.parent_id || ''
    if (total_pages) {
      if (page_index < total_pages) {
        getListSection({
          type: type,
          name: name,
          parentId: parentId,
          page_index: page_index + 1,
          page_size: page_size,
        })
      }
    }
  }

  const [belongToQuiz, setBelongToQuiz] = useState<any>()

  const fetchQuiz = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await QuestionBankAPI.getBelongToQuiz(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  // Xử lý chuyển sang trang kế tiếp của danh sách câu hỏi
  const handlNextPageQuiz = async (params: Object) => {
    const totalPages = belongToQuiz?.meta?.total_pages
    const pageIndex = belongToQuiz?.meta?.page_index as number
    const pageSize = belongToQuiz?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchQuiz(pageIndex + 1, pageSize, params)
      const results = belongToQuiz.quizzes.concat(res?.data?.groups)
      setBelongToQuiz({
        meta: res?.data?.meta,
        quizzes: results,
      })
    }
  }

  // Lấy danh sách câu hỏi
  const getQuiz = async ({ params }: { params: Object }) => {
    const resMentor = await fetchQuiz(1, 30, params)
    setBelongToQuiz(resMentor?.data)
  }

  // Tạo hàm debounce để tìm kiếm câu hỏi
  const debounceSearchMentor = debounce((e) => {
    getQuiz({ params: { name: e, courseId: watch('course_id') } })
  }, 500)

  // Tạo mảng chứa danh sách câu hỏi và bài kiểm tra
  const belongToQuizAndTest =
    belongToQuiz?.quizzes &&
    belongToQuiz?.quizzes?.map((quizzes: any) => ({ label: quizzes?.name, value: quizzes?.id }))

  // useEffect để xử lý khi giá trị của 'course' thay đổi
  useEffect(() => {
    if (watch('course_id') === undefined || watch('course_id') === 'all') {
      setBelongToQuiz(undefined)
      setValue('quiz_id', null)
    }
  }, [watch('course_id')])

  // useEffect để lấy danh sách câu hỏi khi 'course' thay đổi
  useEffect(() => {
    if (watch('course_id') && watch('course_id') !== 'all') {
      getQuiz({ params: { courseId: watch('course_id') } })
    }
  }, [watch('course_id')])

  // // useEffect để đặt giá trị 'quiz' thành null khi 'id' không tồn tại
  useEffect(() => {
    if (watch('course_id')) {
      setValue('quiz_id', null)
    }
  }, [watch('course_id')])

  return (
    <div>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open.state}
        handleClose={handleClose}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
        handleSubmit={handleSave}
        customFooter={
          <div className='modal-body px-lg-17 w-100'>
            <PagiantionSAPP
              currentPage={questionState?.data?.meta?.page_index ?? 1}
              handlePaginationChange={(page: number, pageSize: number) => {
                getListQuestionFilter({ page_index: page, page_size: pageSize })
              }}
              pageSize={questionState?.data?.meta?.page_size ?? 10}
              totalItems={questionState?.data?.meta?.total_records}
            />
          </div>
        }
        classNameFooter='border-0 p-0'
      >
        <div className='d-flex justify-content-between mb-8'>
          <div className='sapp-title-modal-create-class d-flex align-items-center'>
            List Questions
          </div>
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={handleClose}
            cancelButtonCaption='Cancel'
            okButtonCaption='Add'
            okOnClick={handleSave}
            className='justify-content-between d-flex flex-row-reverse'
            classNameCancel='fw-bold me-0'
            classNameSubmit='fw-bold me-5'
            // loading={loading}
          />
        </div>
        <div className='w-100'>
          <div className='row'>
            {/* begin:: Search */}
            <div className='col-xl-2 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                <HookFormTextField
                  control={control}
                  name='question_content'
                  placeholder='Search'
                  defaultValue={queryParams?.question_content}
                  preIcon={
                    <i
                      className={`ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y
                       ms-5
                      `}
                    ></i>
                  }
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            {/* end:: Search */}

            <div className='col-xl-2 col-sm-4 mt-2 '>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='course_id'
                placeholder='Course'
                defaultValue={queryParams.course_id ?? ''}
                showSearch
                handleNextPage={handleNextPageCourse}
                onChange={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  getListSection({ parentId: e, type: 'part' })
                  clearChildren('course')
                }}
                allowClear
                onClear={() => {
                  clearChildren('course')
                }}
                onSearch={async (e) => {
                  debounceSearchCourse(e)
                  return
                }}
                loading={loadingCourse}
                onSelect={() => {
                  getCourseList({ name: '', page_index: 1, page_size: 20 })
                  setSearchCourse('')
                }}
              >
                {DEFAULT_SELECT_ALL?.concat(newCourse)?.map((category) => (
                  <Option key={category.value} value={category.value}>
                    {category.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-2 col-sm-4 mt-2 '>
              <HookFormSelectAntd
                name='part'
                placeholder='Part'
                control={control}
                size='large'
                showSearch
                // className='sapp-h-45px'
                loading={false}
                handleNextPage={() => handlNextPageSection({ type: 'part' })}
                onChange={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  getListSection({ parentId: e, type: 'chapter' })
                }}
                allowClear
                onClear={() => {
                  clearChildren('part')
                }}
              >
                {listSection.part?.data?.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  )
                })}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-2 col-sm-4 mt-2 '>
              <HookFormSelectAntd
                name='chapter'
                placeholder='Chapter'
                control={control}
                size='large'
                // className='sapp-h-45px'
                showSearch
                loading={false}
                handleNextPage={() => handlNextPageSection({ type: 'chapter' })}
                onChange={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  getListSection({ parentId: e, type: 'unit' })
                }}
                allowClear
                onClear={() => {
                  clearChildren('chapter')
                }}
              >
                {listSection.chapter?.data?.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  )
                })}
              </HookFormSelectAntd>
            </div>

            <div className='col-xl-2 col-sm-4 mt-2 '>
              <HookFormSelectAntd
                name='unit'
                placeholder='Unit'
                control={control}
                size='large'
                // className='sapp-h-45px'
                showSearch
                loading={false}
                handleNextPage={() => handlNextPageSection({ type: 'unit' })}
                onChange={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  getListSection({ parentId: e, type: 'activity' })
                }}
                allowClear
                onClear={() => {
                  clearChildren('unit')
                }}
              >
                {listSection.unit?.data?.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  )
                })}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-2 col-sm-4 mt-2 '>
              <HookFormSelectAntd
                name='activity'
                placeholder='Activity'
                control={control}
                size='large'
                // className='sapp-h-45px'
                // showSearch
                loading={false}
                handleNextPage={() => handlNextPageSection({ type: 'activity' })}
                allowClear
              >
                {listSection.activity?.data?.map((e: any) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  )
                })}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-2 col-sm-4 mt-2'>
              <HookFormSelectAntd
                name='quiz_id'
                placeholder='Quiz/Test'
                control={control}
                size='large'
                // className='w-100 sapp-h-45px fs-6 sapp-field_question-select'
                loading={loading}
                onSearch={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  if (!isUndefined(watch('course_id'))) {
                    debounceSearchMentor(e)
                  }
                }}
                onClear={() => setValue('quiz_id', null)}
                handleNextPage={(e: any) => handlNextPageQuiz({ params: { name: e } })}
                showSearch
                allowClear
              >
                {belongToQuizAndTest &&
                  belongToQuizAndTest?.map((e: any, index: any) => {
                    return (
                      <Option key={e.id + index} value={e.value}>
                        {e.label}
                      </Option>
                    )
                  })}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-2 col-sm-4 mt-2'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='item-set'
                placeholder='Item Set'
                defaultValue={queryParams.topic_id ?? ''}
                showSearch
                handleNextPage={debounce(
                  (e: string) => handleNextPageItemSet({ params: { name: e } }),
                  500
                )}
                allowClear
                onFocus={async () => {
                  if (listItemSet?.topics?.length <= 0) {
                    await getListItemSet()
                    return
                  }
                }}
                onClear={() => {
                  setValue('item-set', null)
                }}
                onSearch={async (e) => {
                  debounceSearchItemSet(e)
                  return
                }}
                loading={loadingCourse}
              >
                {listItemSet?.topics?.map((item: ITopicObject, index: number) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-2 col-sm-4 mt-2'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='tag'
                placeholder='Tag'
                defaultValue={queryParams.tag_id ?? ''}
                showSearch
                handleNextPage={debounce(
                  (e: string) => handleNextPageITag({ params: { name: e } }),
                  500
                )}
                allowClear
                onFocus={async () => {
                  if (questionTags?.question_tags?.length <= 0) {
                    await getListQuestionTags()
                    return
                  }
                }}
                onClear={() => {
                  setValue('tag', null)
                }}
                onSearch={async (e) => {
                  debounceSearchTag(e)
                  return
                }}
                loading={loadingCourse}
              >
                {questionTags.question_tags?.map((item: ITopicObject, index: number) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-2 col-sm-4 mt-2 '>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                size='large'
                defaultValue={queryParams?.sortType ?? ''}
              >
                {FILTER_SELECTALL_SORTBY.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-2 col-sm-4 mt-2 '>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams?.fromDate}
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-2 '>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams?.toDate}
              />
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6 mb-8 px-0'>
          <div className=''>
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleResetFilter}
              cancelButtonCaption='Reset'
              okButtonCaption='Search'
              okOnClick={onSubmit}
              className='justify-content-start d-flex'
              // classNameCancel='px-7 py-5 fs-4 lh-1 fw-bold me-5'
              // classNameSubmit='px-8 py-5 fs-4 lh-1 fw-bold me-0'
              loading={loading}
            />
          </div>
        </div>
        <TableListQuestion
          checkedList={selectedRows}
          isCheckedAll={false}
          loading={loading}
          questionList={questionState?.data?.questions}
          toggleCheck={toggleRowSelection}
          toggleCheckAll={() => {}}
          singleCheck
        />
      </SappModal>
    </div>
  )
}
export default ChooseQuestionModal
