import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { QuestionBankAPI } from 'src/apis/question-bank'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import PageLayouts from 'src/components/layout/PageLayouts'
import TableList from 'src/components/question-bank/TableList'
import ListGrouping from 'src/components/user-management/UserListGrouping'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { ITabs, Role } from 'src/type'
import { ITopicList } from 'src/type/question-bank'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'
import { toast } from 'react-hot-toast'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_TOPIC_GR } from 'src/constants/permission'
import ChooseQuestionType from 'src/components/question-bank/choose-question-type/ChooseQuestionType'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: ``,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.topicList,
  },
]

const fieldNames = ['text', 'sortType', 'fromDate', 'toDate']
const initialValues: any = {
  text: '',
  sortType: '',
  fromDate: '',
  toDate: '',
}

const TopicList = () => {
  const [topicList, setTopicList] = useState<ITopicList>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page_index = parseInt(queryParams.get('page_index') as string)
  const [loading, setLoading] = useState(true)
  const [dateField, setDateField] = useState<string>('created_at')
  const page_size = parseInt(queryParams.get('page_size') as any)
  const searchParams = new URLSearchParams(location.search)
  const [openBlocked, setOpenBlocked] = useState<boolean>(false)
  const [openChooseQuestionTypeModal, setOpenChooseQuestionTypeModal] = useState<boolean>(false)

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll, listDataChecked } = useChecked<
    typeof initialValues
  >(topicList?.topics)

  const validationSchema = z.object({
    text: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  // Using validate for input
  const { control, handleSubmit, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const fetchTopic = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await QuestionBankAPI.getTopic({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setTopicList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleResetFilter = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })

    setLoading(true)
    navigate(`${PageLink.TOPICS}`)
    setDateField('')
    toggleCheckAll(false)
    fetchTopic(1, 10)
  }

  const navigate = useNavigate()

  const getParams = (text: string, sortType: string, fromDate?: any, toDate?: any) => ({
    text,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
  })

  const queryParam = {
    text: searchParams.get('text'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: getValues('text'),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))

    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('text').trimStart().trimEnd(),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : ''
      )
    )
    handleChangeParams(page_index || 1, page_size || 10)

    setLoading(true)
    fetchTopic(1, 10, cleanedParams)
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParam.fromDate)
  const dateQueryToDate = getDateInfo(queryParam.toDate)

  const cleanedParams = cleanParamsAPI(
    getParams(
      queryParam.text ?? '',
      queryParam.sortType ?? '',
      queryParam.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParam.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : ''
    )
  )

  const deleteItemTopic = async (id: string) => {
    try {
      await QuestionBankAPI.deleteTopic(id)
    } catch (error: any) {}
  }

  const blockTopics = async () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('text').trimStart().trimEnd(),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : ''
      )
    )
    setLoading(true)

    try {
      for (const id of checkedList) {
        await deleteItemTopic(id)
      }
    } catch (error) {
    } finally {
      toggleCheckAll(!listDataChecked)
      setOpenBlocked(false)
      setLoading(false)
      fetchTopic(1, 10, cleanedParams)
    }
  }
  const { profileMe } = useUserContext()
  const allowRenderCreateTopic: boolean =
    profileMe?.roles?.some(
      (role: Role) =>
        role.permissions?.includes(TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC) ||
        role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false
  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.topicList} breadcrumbs={breadcrumbs}>
      <ChooseQuestionType
        isSingleQuestion={true}
        open={openChooseQuestionTypeModal}
        setOpen={setOpenChooseQuestionTypeModal}
      />
      <div className='card'>
        <div className='px-10 border-0 pt-10'>
          <div>
            <div className='row'>
              {/* begin:: filter */}
              {/* <div className='col-sm-6 px-0'> */}
              <div className='col-xl-3 col-sm-4'>
                <div className='card-title justify-content-center mb-0 mx-0'>
                  <HookFormTextField
                    control={control}
                    name='text'
                    placeholder='Search'
                    defaultValue={queryParam.text ?? ''}
                    onSubmit={onSubmit}
                    isListScreen
                  />
                </div>
              </div>

              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  name='sortType'
                  placeholder='Sort By'
                  control={control}
                  size='large'
                  defaultValue={queryParam.sortType ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {FILTER_SELECTALL_SORTBY.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParam.fromDate ?? ''}
                  isListScreen
                />
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParam.toDate ?? ''}
                  isListScreen
                />
              </div>
              {/* end:: filter */}
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={handleSubmit(onSubmit)}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
          <>
            {checkedList.length > 0 ? (
              <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3 pe-xl-0'>
                <ListGrouping
                  selected={checkedList}
                  blockUser={() => {}}
                  openBlocked={false}
                  setOpenBlocked={() => {}}
                  title='Delete'
                />
              </div>
            ) : (
              <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0 d-flex justify-content-end gap-4 flex-wrap'>
                <div className='justify-content-end d-flex my-0 '>
                  {allowRenderCreateTopic && (
                    <ButtonIconPrimary
                      iconName='plus'
                      title={LANG_SIDEBAR.addSingleQuestion}
                      size='small'
                      link={`${PageLink.TOPIC + (location.search || '')}`}
                      onClick={() => setOpenChooseQuestionTypeModal(true)}
                    />
                  )}
                </div>
                <div className='justify-content-end d-flex my-0'>
                  {allowRenderCreateTopic && (
                    <ButtonIconPrimary
                      iconName='plus'
                      title={LANG_SIDEBAR.addItemSet}
                      size='small'
                      link={`${PageLink.TOPIC + (location.search || '')}`}
                      onClick={() => navigate(`${PageLink.TOPIC + (location.search || '')}`)}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        </div>
        <TableList
          topicList={topicList}
          setTopicList={setTopicList}
          cleanedParams={cleanedParams}
          loading={loading}
          setLoading={setLoading}
          getParams={getParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
          fetchTopic={fetchTopic}
          queryParam={queryParam}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(TopicList)
