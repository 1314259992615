import { zodResolver } from '@hookform/resolvers/zod'
import { UploadFile } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { KTIcon } from 'src/_metronic/helpers'
import { QuestionBankAPI } from 'src/apis/question-bank'
import { ResourcesAPI } from 'src/apis/resources'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
// import SettingTab from 'src/components/courses/course-detail/create-tabs/tabVideo/tabSettings/TabSetting'
import TabVideoQuestion from 'src/components/courses/course-detail/create-tabs/tabVideo/tabVideoQuestion/TabVideoQuestion'
import {
  ATTEMPT,
  GRADED_ASSIGNMENT,
  GRADING_PREFERENCE,
  PASS_POINT,
  TEST_MODE,
  TYPE_OF_TEST,
} from 'src/components/courses/shared/consts'
import { QuestionProviderProvider } from 'src/components/question-bank/shared/providers/QuestionProvider'
import VideoTimeLine from 'src/components/video-question/videoTimeLine'
import {
  VALIDATE_NUMBER,
  VALIDATE_PASS_POINT,
  VALIDATION_FILED,
  VALIDATION_MIN_EQUAL,
} from 'src/constants'
import { ISearchResources } from 'src/type/resource'
import { htmlToRaw } from 'src/utils'
import { ControlBar, Player } from 'video-react'
import { z } from 'zod'
import ResourceUploadFile from './ResourceUploadFile'
import './ResourceVideoSetting.scss'
import { ResourceAPI } from 'src/apis/resource-bank'
import { useConfirm } from 'src/hooks/use-confirm'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'

interface IProps {
  open: {
    status: boolean
    resource_id?: string
    queryPrams?: ISearchResources
    fileBreadcrumb?: {
      name: string
      id: string
      parent_id: string
    }[]
    isEdit?: boolean
  }
  setOpen: any
  // url?: string
  // name?: string
  // quiz_id?: string
  // time_line?:any
  tabActive?: number
  // resourceLocation: RESOURCE_LOCATION
  gotoPage: (params: ISearchResources) => Promise<void>
  fetchGetResource: () => void
}
export interface IQuiz {
  name: string
  type: string
  testMode: string
  gradedAssignment: string
  time: {
    hours: string
    mins: string
  }
  duration: {
    mins: string
    sec: String
  }
  gradingPreference: string
  passPoint: {
    type: string
    value: string
  }
  attempt: {
    type: string
    value: string
  }
}
const defaultValues = {
  name: '',
  type: TYPE_OF_TEST.multipleChoice.value,
  testMode: TEST_MODE.tutor.value,
  gradedAssignment: GRADED_ASSIGNMENT.no.value,
  time: {
    hours: '',
    mins: '',
  },
  duration: {
    mins: '',
    sec: '',
  },
  gradingPreference: GRADING_PREFERENCE.each.value,
  passPoint: {
    type: PASS_POINT.auto.value,
    value: '',
  },
  attempt: {
    type: ATTEMPT.unlimited.value,
    value: '',
  },
}

const ResourceVideoSetting = ({
  open,
  setOpen,
  tabActive,
  // resourceLocation,
  gotoPage,
  fetchGetResource,
}: IProps) => {
  const [uploadFile, setUploadFile] = useState<UploadFile[] | undefined>()
  const uploadFileRef = useRef<UploadFile[] | undefined>()

  const [hasVideo, setHasVideo] = useState<boolean>(false)
  const { confirm, contextHolder } = useConfirm()
  useEffect(() => {
    let resource_id = open.resource_id
    if (!resource_id) {
      const video = uploadFile?.find((e: any) => e.suffix_type === 'VIDEO') as any

      const hasOldVIdeo = uploadFileRef.current?.some((e: any) => {
        if (e.id && e.suffix_type && e.suffix_type === 'VIDEO') {
          return true
        }
        return false
      }, {} as { [key: string]: boolean })

      if (video) {
        setHasVideo(true)
        resource_id = video.id
        if (!hasOldVIdeo) {
          setTab('SETTING')
        }
      } else {
        setHasVideo(false)
      }
    } else {
      setTab('SETTING')
      setHasVideo(true)
    }

    async function getData() {
      if (resource_id) {
        const res = await ResourcesAPI.getUrl(resource_id)
        setData(res.data)
      }
    }
    if (resource_id) {
      try {
        getData()
      } catch (error) {}
    } else {
      setData(undefined)
    }
    uploadFileRef.current = uploadFile
  }, [uploadFile, open])

  const [data, setData] = useState<
    | {
        quiz_id: string
        sub_url: string
        time_line?: Array<any>
        name: string
      }
    | undefined
  >()
  const [tab, setTab] = useState<'UPLOAD_FILE' | 'SETTING'>('UPLOAD_FILE')
  const [activeTab, setActiveTab] = useState(1)
  //handle load video done, then fill time by duration
  const [firstLoad, setFirstLoad] = useState(true)
  //handle block submit on submiting
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [currentTime, setCurrentTime] = useState<any>(null)
  const [listTimeLine, setListTimeLine] = useState<
    | {
        menuName: string
        questionId: string
        time: number
      }[]
    | []
  >([])
  const [listTimeLineMenu, setListTimeLineMenu] = useState<
    | {
        menuName: string
        time: number
      }[]
    | []
  >([])
  const [duration, setDuration] = useState<any>()
  const [openQuestionSelect, setOpenQuestionSelect] = useState<any>({
    state: false,
    data: {},
    edit: false,
    index: undefined,
  })
  const [openMenuSelect, setOpenMenuSelect] = useState<any>({
    state: false,
    data: {},
    edit: false,
  })
  const [sortedListTimeLine, setSortedListTimeLine] = useState<any>()
  const [sortedListTimeLineMenu, setSortedListTimeLineMenu] = useState<any>()

  const validationSchema = z.object({
    name: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    type: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    testMode: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
    gradedAssignment: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED),
    time: z.object({
      hours: z
        .string()
        .regex(/^[0-9]*$/, VALIDATE_NUMBER)
        .refine(
          (val) => {
            if (
              getValues('testMode') === TEST_MODE.timed.value &&
              !Number(val) &&
              !Number(getValues('time.mins'))
            ) {
              return false
            }
            return true
          },
          { message: VALIDATION_FILED }
        )
        .optional(),
      mins: z
        .string()
        .regex(/^[0-9]*$/, VALIDATE_NUMBER)
        .refine(
          (val) => {
            if (
              !Number(val) &&
              !Number(getValues('time.hours')) &&
              getValues('testMode') === TEST_MODE.timed.value
            ) {
              return false
            }
            return true
          },
          { message: VALIDATION_FILED }
        )
        .optional(),
    }),

    gradingPreference: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, VALIDATION_FILED),
    passPoint: z.object({
      type: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
      value: z
        .string()
        .regex(/^[0-9]*$/, VALIDATE_NUMBER)
        .superRefine((arg: any, ctx) => {
          if (getValues('passPoint.type') === PASS_POINT.manual.value) {
            if (!Number(arg) || Number(arg) > 100) {
              ctx.addIssue({
                message: VALIDATE_PASS_POINT,
                code: 'custom',
              })
            }
          }
          return true
        })
        .optional(),
    }),
    attempt: z.object({
      type: z.string({ required_error: VALIDATION_FILED }).trim().min(1, VALIDATION_FILED),
      value: z
        .string()
        .regex(/^[0-9]*$/, VALIDATE_NUMBER)
        .refine(
          (val) => {
            if (getValues('attempt.type') === ATTEMPT.limited.value && (!val || Number(val) < 1)) {
              return false
            }
            return true
          },
          { message: VALIDATION_MIN_EQUAL(1) }
        )
        .optional(),
    }),
    quiz_question_type: z.string().optional(),
  })
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { isSubmitted, errors },
    trigger,
    clearErrors,
  } = useForm<IQuiz>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  const watchHours = watch('time.hours')
  const watchMins = watch('time.mins')
  const [editAble, setEditAble] = useState(true)
  //handle sort list timeline base on time
  useEffect(() => {
    const newListTimeLine = [...listTimeLine]
    newListTimeLine.sort((a, b) => a.time - b.time)
    setSortedListTimeLine(newListTimeLine)
  }, [listTimeLine])
  useEffect(() => {
    const newListTimeLine = [...listTimeLineMenu]
    newListTimeLine.sort((a, b) => a.time - b.time)
    setSortedListTimeLineMenu(newListTimeLine)
  }, [listTimeLineMenu])

  useEffect(() => {
    async function getQuizDetail() {
      if (data?.quiz_id) {
        try {
          const result = await QuestionBankAPI.getQuizDetail(data?.quiz_id)
          const listQuestion = result.data.multiple_choice_questions.concat(
            result.data.constructed_questions
          )

          const listTimeLineData = [...listQuestion]
          handleConvertListTimeLine(listTimeLineData)

          const oldData = convertResultToFormValues(result)
          Object.entries(oldData || {}).forEach(([key, value]: [any, any]) => {
            setValue(key, value)
          })
          if (result.data.status === 'LOCKED') {
            setEditAble(false)
          } else {
            setEditAble(true)
          }

          setFirstLoad(false)
        } catch (err: any) {
          if (err?.response?.status === 422) {
            toast.error('Quiz not found!')
          }
          console.error(err)
        }
      }
    }
    if (data?.quiz_id) {
      getQuizDetail()
    }
  }, [data])

  useEffect(() => {
    if (tab === 'SETTING') {
      setActiveTab(1)
    }
  }, [tab])
  useEffect(() => {
    if (data?.time_line) {
      const listMenu = [...data?.time_line]
      handleConvertListTimeLineMenu(listMenu)
    }
  }, [data?.time_line])

  useEffect(() => {
    if (duration > 0 && watchHours === '0' && watchMins === '0' && !firstLoad) {
      setValue('time.mins', Math.ceil(duration / 60)?.toString())
    }
  }, [duration, firstLoad])

  const handleConvertListTimeLine = (data: any) => {
    let newArr = []
    for (let e of data) {
      if (e != null) {
        newArr.push({
          menuName: htmlToRaw(e.question_content),
          questionId: e.id,
          time: e.time,
        })
      }
    }
    setListTimeLine(newArr)
  }
  const handleConvertListTimeLineMenu = (data: any) => {
    let newArr = []
    for (let e of data) {
      if (e != null) {
        newArr.push({
          menuName: e.text,
          time: e.time,
        })
      }
    }
    setListTimeLineMenu(newArr)
  }
  //convert data to fill form
  function convertResultToFormValues(result: any) {
    return {
      name: result.data.name,
      type: result.data.quiz_type.toLowerCase(),
      testMode: result.data.is_tutor ? TEST_MODE.tutor.value : TEST_MODE.timed.value,
      gradedAssignment: result.data.is_graded
        ? GRADED_ASSIGNMENT.yes.value
        : GRADED_ASSIGNMENT.no.value,
      time: {
        hours: Math.floor(result.data.quiz_timed / 60)?.toString(),
        mins: (result.data.quiz_timed % 60)?.toString(),
      },
      gradingPreference:
        result.data.grading_preference === 'AFTER_EACH_QUESTION'
          ? GRADING_PREFERENCE.each.value
          : GRADING_PREFERENCE.all.value,
      passPoint: {
        type:
          result.data.grading_method === 'AUTO' ? PASS_POINT.auto.value : PASS_POINT.manual.value,
        value: result.data.required_percent_score
          ? result.data.required_percent_score?.toString()
          : '',
      },
      attempt: {
        type: result.data.is_limited ? ATTEMPT.limited.value : ATTEMPT.unlimited.value,
        value: result.data.limit_count ? result.data.limit_count?.toString() : '',
      },
      quiz_question_type: result.data.quiz_question_type,
    }
  }
  const onSubmit = async (dataValue: any) => {
    if (!hasVideo) {
      handleClose()
    } else {
      if (Object.values(errors || {})?.[0] && tab === 'UPLOAD_FILE') {
        toast.error('Có lỗi trong tab Setting')
        return
      }
    }

    let quiz_timeline = []
    let time_line_text = []
    try {
      fetchGetResource()
      setLoadingSubmit(true)
      for (let e of sortedListTimeLine) {
        quiz_timeline.push({
          time: e.time,
          question_id: e.questionId,
        })
      }
      for (let e of sortedListTimeLineMenu) {
        time_line_text.push({
          time: e.time,
          text: e.menuName,
        })
      }
      const payload = {
        name: dataValue.name,
        quiz_question_mode: 'ALL',
        status: 'PUBLISHED',
        is_published: true,
        is_public: true,

        number_of_multiple_choice_questions:
          Number(dataValue.number_of_multiple_choice_questions) || 0,
        number_of_essay_questions: Number(dataValue.number_of_essay_questions) | 0,

        is_graded: dataValue.gradedAssignment === GRADED_ASSIGNMENT.yes.value ? true : false,

        grading_method: dataValue.passPoint.type,
        grading_preference: dataValue.gradingPreference,
        ...(dataValue.passPoint.type === PASS_POINT.manual.value
          ? { required_percent_score: +dataValue.passPoint.value }
          : { required_percent_score: 50 }),

        is_limited: dataValue.attempt.type === ATTEMPT.limited.value ? true : false,
        ...(dataValue.attempt.type === ATTEMPT.limited.value
          ? {
              limit_count: Number(dataValue.attempt.value) || 0,
            }
          : { limit_count: 0 }),

        is_tutor: dataValue.testMode === TEST_MODE.tutor.value ? true : false,
        ...(dataValue.testMode === TEST_MODE.timed.value && {
          quiz_timed: Number(dataValue.time.hours) * 60 + Number(dataValue.time.mins),
        }),
        quiz_type: 'QUIZ',
        quiz_question_type: dataValue.quiz_question_type,
        quiz_timeline: quiz_timeline,
        time_line: time_line_text,
      }
      await QuestionBankAPI.updateQuizVideo({
        id: data?.quiz_id,
        data: payload,
      })
      reset()
      setListTimeLine([])
      setListTimeLineMenu([])
      setOpen({ status: false })
      setDuration(0)
      setCurrentTime(0)
      setFirstLoad(true)
      setActiveTab(0)
      setData(undefined)
      gotoPage({})
      setUploadFile([])
      setTab('UPLOAD_FILE')
    } catch (err) {
      console.error(err)
    } finally {
      setLoadingSubmit(false)
      fetchGetResource()
    }
  }

  const handleClose = () => {
    reset()
    setListTimeLine([])
    setListTimeLineMenu([])
    setOpen({ state: false })
    setDuration(0)
    setCurrentTime(0)
    setFirstLoad(true)
    setData(undefined)
    setActiveTab(0)
    gotoPage({})
    setUploadFile([])
    setTab('UPLOAD_FILE')
    fetchGetResource()
  }

  const handleCancel = async () => {
    if (uploadFile?.[0]) {
      try {
        if (open.isEdit) {
          handleClose()
          return
        }
        const resourceIds = uploadFile.map((e: any) => e.id)
        await ResourceAPI.deleteResource(resourceIds)
      } catch (error) {
      } finally {
      }
    }
    handleClose()
  }
  //handle delete item
  const handleDeleteTimeline = (e: any) => {
    if (activeTab === 1) {
      setSortedListTimeLine((prev: any) => {
        let arr = [...prev]
        arr.splice(e, 1)
        setListTimeLine(arr)
        return arr
      })
    } else {
      setSortedListTimeLineMenu((prev: any) => {
        let arr = [...prev]
        arr.splice(e, 1)
        setListTimeLineMenu(arr)
        return arr
      })
    }
  }

  //show drawer when tab is questions
  const handleEditTimeLine = (e: number) => {
    if (activeTab === 1) {
      setOpenQuestionSelect({ state: true, edit: true, data: sortedListTimeLine[e], index: e })
    }
  }

  const getResourceLocation = () => {
    let location = ''
    if (Array.isArray(open.fileBreadcrumb)) {
      location = open.fileBreadcrumb.map((e) => e.name).join('/')
    }
    return location
  }

  return (
    <>
      {contextHolder}
      <SappModal
        open={open.status}
        title={`${open?.resource_id ? 'Edit Video' : 'Upload File'}`}
        cancelButtonCaption='Cancel'
        okButtonCaption={''}
        dialogClassName={`modal-dialog-scrollable ${
          tab === 'SETTING'
            ? 'modal-fullscreen  sapp-resource-setting m-0 '
            : 'mw-650px modal-dialog-centered'
        }`}
        confirmOnclose
        handleClose={handleCancel}
        handleSubmit={fetchGetResource}
        loading={false}
        showFooter={false}
        classBody={`${tab === 'SETTING' ? 'p-0' : 'py-10 modal-body px-lg-17'}`}
        showClassHeightBody={false}
        enforceFocus={false}
        classNameContent={`overflow-hidden ${
          open.resource_id ? 'sapp-resource-setting-update' : 'sapp-resource-setting-create'
        }`}
        {...(!open?.resource_id && {
          customTitle: (title) => (
            <div className='d-flex sapp-flex-1 justify-content-between align-items-end'>
              <div className='w-100'>
                <h2 className='mb-10 text-capitalize'>{title}</h2>
                <div className='d-flex column-gap-6'>
                  <div
                    className={`fs-5 pb-2 sapp-modal_upload-file_tab_title ${
                      tab === 'UPLOAD_FILE' ? '_checked' : 'text-gray-500'
                    } `}
                    onClick={() => setTab('UPLOAD_FILE')}
                  >
                    File
                  </div>
                  {hasVideo && (
                    <div
                      className={`fs-5 pb-2 sapp-modal_upload-file_tab_title ${
                        tab === 'SETTING' ? '_checked' : 'text-gray-500'
                      } `}
                      onClick={() => setTab('SETTING')}
                    >
                      Setting
                    </div>
                  )}
                </div>
              </div>
              <div>
                <ButtonPrimary
                  title={`${hasVideo ? 'Finish' : 'Save'}`}
                  onClick={() => {
                    if (!hasVideo) {
                      handleClose()
                      return
                    }
                    handleSubmit((e) => onSubmit(e))()
                  }}
                  loading={loadingSubmit}
                ></ButtonPrimary>
              </div>
            </div>
          ),
        })}
      >
        <QuestionProviderProvider>
          {/* <Spin spinning={firstLoad}> */}

          <div className={`${tab === 'UPLOAD_FILE' ? 'd-block' : 'd-none'}`}>
            <ResourceUploadFile
              fileType={'ALL'}
              resourceLocation={getResourceLocation() as RESOURCE_LOCATION}
              tab={'UPLOAD_FILE'}
              isMultiple
              uploadFile={uploadFile}
              setUploadFile={setUploadFile}
              parentId={open.queryPrams?.parent_id || ''}
            ></ResourceUploadFile>
          </div>

          <div className={`${tab === 'SETTING' ? 'd-flex h-100' : 'd-none'}`}>
            {/* <button onClick={handleSubmit(onSubmit)}></button> */}
            <div
              className='d-flex flex-column h-100 bg-white sapp-tabside-container'
              style={{ position: 'fixed', zIndex: 99999999 }}
            >
              <div className='flex-grow-1'>
                <ButtonIcon
                  title='Timeline'
                  classTitle='sapp-title-video-setting'
                  className={
                    'd-flex flex-column align-items-center justify-content-center sapp-tab-btn-video gap-2'
                  }
                  customButton={`btn-light btn-active-light-primary ${
                    activeTab === 0 && 'active'
                  } rounded-0 bg-white`}
                  onClick={() => setActiveTab(0)}
                >
                  <KTIcon
                    iconName='menu'
                    className={`fs-2x p-0 ${activeTab !== 0 && 'sapp-icon-btn-tab'}`}
                    iconType='solid'
                  />
                </ButtonIcon>
                <ButtonIcon
                  title={'Question'}
                  classTitle='sapp-title-video-setting'
                  className={
                    'd-flex flex-column align-items-center justify-content-center sapp-tab-btn-video gap-2'
                  }
                  customButton={`btn-light btn-active-light-primary ${
                    activeTab === 1 && 'active'
                  } rounded-0 bg-white`}
                  onClick={() => setActiveTab(1)}
                >
                  <KTIcon
                    iconName='question'
                    className={`fs-2x p-0 ${activeTab !== 1 && 'sapp-icon-btn-tab'}`}
                    iconType='solid'
                  />
                </ButtonIcon>
              </div>
            </div>
            <div className='w-100 h-100 sapp-video-setting-content-container '>
              <div className='d-flex w-100 h-100'>
                <div className='sapp-flex-1'>
                  {activeTab === 1 && (
                    <div className='px-8 pt-8'>
                      <HookFormTextField
                        control={control}
                        name='name'
                        placeholder='Quiz Name'
                        disabled={!editAble}
                        // skeleton={loadingFetchData}
                        label='Quiz Name'
                        required
                      ></HookFormTextField>
                    </div>
                  )}
                  {/* {duration ? (
                    <> */}
                  {activeTab !== 2 && (
                    <TabVideoQuestion
                      actionDelete={handleDeleteTimeline}
                      tab={activeTab}
                      setOpenQuestionSelect={
                        activeTab === 1 ? setOpenQuestionSelect : setOpenMenuSelect
                      }
                      listTimeLine={activeTab === 1 ? sortedListTimeLine : sortedListTimeLineMenu}
                      currentTime={currentTime}
                      setListTimeLine={activeTab === 1 ? setListTimeLine : setListTimeLineMenu}
                      actionEdit={handleEditTimeLine}
                    />
                  )}
                  {/* {activeTab === 2 && (
                    <SettingTab
                      id={data?.quiz_id || ''}
                      clearErrors={clearErrors}
                      control={control}
                      setValue={setValue}
                      trigger={trigger}
                      watch={watch}
                      editAble={editAble}
                      isSubmitted={isSubmitted}
                    />
                  )} */}
                  {/* </> */}
                  {/* ) : (
                    <></>
                  )} */}
                </div>
                <div className='sapp-video-container p-8 ps-12 pe-12 pb-14 w-50 mw-900px'>
                  <div className='d-flex justify-content-between mb-8'>
                    <div className='sapp-questionTab-title'>{data?.name} &nbsp;</div>
                    {open.resource_id && (
                      <div className={`flex-end`}>
                        <SAPPDialogButtonsCancelSubmit
                          cancelClick={() => {
                            let body = ['Bạn có chắc chắn muốn huỷ không?']
                            confirm({
                              okButtonCaption: 'Yes',
                              cancelButtonCaption: 'No',
                              body,
                              onClick: async () => {
                                handleCancel()
                              },
                            })
                          }}
                          cancelButtonCaption={'Cancel'}
                          okButtonCaption={'Finish'}
                          okOnClick={
                            handleSubmit(onSubmit)
                            // reset()
                          }
                          loading={loadingSubmit}
                          className='justify-content-center d-flex m-0'
                          // loading={loading}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    {data?.sub_url ? (
                      <VideoTimeLine
                        url={data?.sub_url}
                        currentTime={currentTime}
                        setCurrentTime={setCurrentTime}
                        listTimeLine={listTimeLine}
                        setListTimeLine={setListTimeLine}
                        duration={duration}
                        setDuration={setDuration}
                        openQuestionSelect={openQuestionSelect}
                        setOpenQuestionSelect={setOpenQuestionSelect}
                        openMenuSelect={openMenuSelect}
                        setOpenMenuSelect={setOpenMenuSelect}
                        setListTimeLineMenu={setListTimeLineMenu}
                        setSortedListTimeLine={setSortedListTimeLine}
                      />
                    ) : (
                      <Player aspectRatio='16:9'>
                        <source src={'url'} />
                        <ControlBar disableCompletely={true} />
                      </Player>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </Spin> */}
        </QuestionProviderProvider>
      </SappModal>
    </>
  )
}
export default ResourceVideoSetting
