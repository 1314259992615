import {VALIDATION_FILED} from 'src/constants'
import {z} from 'zod'
import {IInputQuestionProps, QUESTION_LEVELS} from '../interfaces'

export const VALIDATION_ANSWERS_MINLENGTH = (number: number = 2) => ({
  message: `The answers must have at least ${number} items`,
  type: 'custom',
  code: z.ZodIssueCode.custom,
  path: ['customAnswersError'],
})

export const VALIDATION_ONE_TRUE_ANSWER = {
  message: 'Only one choice can be true, please select only one correct answer',
  type: 'custom',
  code: z.ZodIssueCode.custom,
  path: ['customAnswersError'],
}

export const VALIDATION_GAP = 'The minimum number of GROUP must be equal to the number of Vacant'

export const defaultQuestionValues: IInputQuestionProps = {
  level: QUESTION_LEVELS.FUNDAMENTAL,
  is_self_reflection: 'false',
  course: '',
  part: '',
  chapter: '',
  unit: '',
  activity: '',
  category: [],
  display_type: 'HORIZONTAL',
  question_content: '',
  solution: '',
  hint: '',
  tags: [],
  quiz_id: '',
  is_independent_question: false,
}

export const validationQuestionSchema = {
  level: z
    .string({required_error: VALIDATION_FILED})
    .trim()
    .min(1, VALIDATION_FILED)
    .refine((e) => Object.values(QUESTION_LEVELS).includes(e as QUESTION_LEVELS), VALIDATION_FILED),
  course: z.string().optional(),
  part: z.string().optional(),
  chapter: z.string({required_error: VALIDATION_FILED}).optional(),
  unit: z.string().optional(),
  activity: z.string().optional(),
  category: z
    .array(
      z.object({
        id: z.string().optional(),
      })
    )
    .refine((data) => data.length > 0, {
      message: VALIDATION_FILED,
    })
    .default([]),
  display_type: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
  question_content: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
  solution: z.string().optional(),
  hint: z.string(),
  tags: z.array(
    z.object({
      id: z.string().optional(),
      name: z.string().optional(),
    })
  ),
  quiz_id: z.any(),
  is_self_reflection: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
  is_independent_question: z.boolean().default(false),
}
