import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { EventAPI } from 'src/apis/short-course/event'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink, VALIDATION_FILED } from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import useShopFilter from 'src/hooks/use-shop-filter'
import { RobotMeta } from 'src/type/shop/common'
import { IEventForm } from 'src/type/shop/event'
import { metaValidationSchema } from 'src/utils/shop/validation'
import { z } from 'zod'
import { IStep } from '../faqs/FaqDetail'
import CreateAndUpdateContent from './CreateAndUpdateContent'
import CreateAndUpdateInfo from './CreateAndUpdateInfo'
import CreateAndUpdateSetting from './CreateAndUpdateSetting'

const EventDetail = () => {
  const { confirm, contextHolder } = useConfirm()
  const { id } = useParams()
  const navigate = useNavigate() // hook để điều hướng lập trình
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [newStep, setNewStep] = useState<IStep[]>([
    {
      title: 'Info',
      status: 'current',
      link: '',
    },
    {
      title: 'Content',
      status: '',
      link: '',
    },
    {
      title: 'Settings',
      status: '',
      link: '',
    },
  ])

  const validationFieldRefinement = (condition: boolean, path: string) => {
    const fieldNumberMap: Record<string, string> = {
      price_number: 'Price',
      total_seat_number: 'Total seat',
    }

    return {
      path: [path],
      message:
        condition && fieldNumberMap[path]
          ? `${fieldNumberMap[path]} must be bigger than 0`
          : VALIDATION_FILED,
      check: (schema: any) => {
        if (!condition) return true // Skip validation if condition is false

        // Special check for 'price' field to ensure it is greater than 0
        if (path === 'price_number' && schema.price_number === '0') {
          return false
        }

        return !!schema[path] // Ensure field exists and is truthy
      },
    }
  }

  /**
   * Validation cho các trường trong HookForm
   */
  const validationSchema = z
    .object({
      title: z.string().trim().min(1, VALIDATION_FILED),
      content: z.string().nullable().optional(),
      thumbnail: z.any(),
      start_time: z
        .any()
        .refine((val) => val !== null && val !== undefined, { message: VALIDATION_FILED }),
      end_time: z
        .any()
        .refine((val) => val !== null && val !== undefined, { message: VALIDATION_FILED }),
      construction_mode: z.string().min(1, VALIDATION_FILED),
      address: z.string().trim().min(1, VALIDATION_FILED),
      categories: z.array(z.string()).optional().default([]),
      cta: z.string().trim().min(1, VALIDATION_FILED),
      cta_title: z.any(),
      cta_url: z.any(),
      is_charge: z.string().min(1, VALIDATION_FILED),
      price_number: z.any(),
      total_seat_number: z.any(),
      suffix: z.string().optional(),
      meta_title: z.any(),
      meta_description: z.any(),
      focus_keyword: z.string().optional(),
      robot_meta: metaValidationSchema,
      canonical_url: z.string().optional(),
      status: z.string().trim().min(1, VALIDATION_FILED),
      shop_faq_questions: z.any().optional().default([]),
      primary_event_category_id: z.string().optional(),
      faq_questions: z
        .array(
          z.object({
            id: z.string(),
            name: z.string(),
          })
        )
        .transform((val) => {
          return val?.map((item) => item.id)
        })
        .default([]),
    })
    .superRefine((schema, ctx) => {
      const conditions = [
        validationFieldRefinement(schema.is_charge === 'true', 'price_number'),
        validationFieldRefinement(schema.is_charge === 'true', 'total_seat_number'),
        validationFieldRefinement(schema.cta === 'true', 'cta_title'),
        validationFieldRefinement(schema.cta === 'true', 'cta_url'),
      ]

      conditions.forEach(({ path, message, check }) => {
        if (!check(schema)) {
          ctx.addIssue({
            path,
            message,
            code: 'custom',
          })
        }
      })
    })

  const EventForm = useForm<IEventForm>({
    mode: 'onSubmit',
    resolver: zodResolver(validationSchema),
    defaultValues: {
      cta_title: null,
      cta_url: null,
      price_number: null,
      total_seat_number: null,
    },
  })
  const { handleSubmit, setValue, reset } = EventForm

  const useFilter = useShopFilter({
    callback: EventAPI.getEventCategories,
    type: 'event',
    key: 'eventCategories',
  })
  const { setDataList } = useFilter

  const loadData = async () => {
    try {
      if (id) {
        const { data } = await EventAPI.getEvent(id)
        const robotMeta = data?.robot_meta
        setValue('status', data.status)
        setValue('title', data.title ?? '')
        setValue('content', data.content ?? '')
        setValue('thumbnail', data.thumbnail?.ORIGIN ?? '')
        setValue('categories', [])
        setValue('start_time', data.start_time)
        setValue('end_time', data.end_time)
        setValue('address', data.address ?? '')
        setValue('cta', String(data.cta) ?? 'false')
        setValue('cta_title', data.cta_title)
        setValue('cta_url', data.cta_url)
        setValue('is_charge', String(data?.is_charge) ?? 'false')
        setValue('price_number', data.price_number?.toString())
        setValue('total_seat_number', data?.total_seat_number?.toString())
        setValue('construction_mode', data.construction_mode)
        setValue('primary_event_category_id', data.primary_shop_event_category.id)
        setValue('prefix_url', data.prefix_url ?? '')
        setValue('focus_keyword', data.focus_keyword ?? '')
        setValue('canonical_url', data.canonical_url ?? '')
        setValue('meta_title', data.meta_title ?? '')
        setValue('meta_description', data.meta_description ?? '')
        setValue(
          'categories',
          data.shop_event_categories.map((item) => item.id)
        )
        setValue(
          'faq_questions',
          data.shop_faq_questions?.map((item) => ({ id: item.id, name: item.title }))
        )
        Object.keys(robotMeta).forEach((key: string) => {
          setValue(`robot_meta.${key as keyof RobotMeta}`, robotMeta[`${key as keyof RobotMeta}`])
        })

        if (data.shop_event_categories.length) {
          setDataList((prev) => {
            const result = data.shop_event_categories.map((item) => ({
              id: item.id,
              name: item.title,
            }))
            prev.data.push(...result)
            return prev
          })
        }

        if (data.primary_shop_event_category) {
          setDataList((prev) => {
            const result = prev.data.find(
              (item) => item?.id === data.primary_shop_event_category?.id
            )
            if (!result) {
              prev.data.push({
                id: data.primary_shop_event_category.id,
                name: data.primary_shop_event_category.title,
              })
            }
            return prev
          })
        }
      } else {
        reset()
      }
    } catch (error) {
      // Do nothing
    }
  }

  const onSubmit = async (data: IEventForm) => {
    try {
      setIsLoading(true)

      if (!data.primary_event_category_id) delete data.primary_event_category_id
      if (id && id !== 'undefined') {
        if (!data.categories) {
          data.categories = []
        }
        await EventAPI.editEvent({ id: id, data: { ...data } })
        if (data.thumbnail && typeof data.thumbnail !== 'string') {
          await EventAPI.uploadThumbnail({ id, thumbnail: data.thumbnail })
        }
        toast.success('Update The Event Successfully!')
      } else {
        const res = await EventAPI.createEvent({ data })
        if (data.thumbnail && res.data.id) {
          await EventAPI.uploadThumbnail({ id: res.data.id, thumbnail: data.thumbnail })
        }
        toast.success('Create The Event Successfully!')
        navigate(`${PageLink.SHOP_NEW_EVENT}/${res.data.id}`)
      }
      handleNextStep()
    } catch (error) {
      // do nothing
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [id])

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        navigate(PageLink.SHOP_EVENT)
      },
    })
  }

  const handleNextStep = () => {
    if (newStep[newStep.length - 1].status === 'current') {
      navigate(PageLink.SHOP_EVENT)
    } else {
      setNewStep((prev: IStep[]) => {
        const indexItem = prev.findIndex((item) => item.status === 'current') + 1
        return prev.map((step: IStep, index: number) => ({
          ...step,
          status:
            index < indexItem
              ? 'active'
              : index === indexItem && index <= prev.length - 1
              ? 'current'
              : '',
        }))
      })
    }
  }
  return (
    <PageLayouts
      titleTab='New Event'
      pageTitle=''
      breadcrumbs={[]}
      showSideBar={false}
      classNameLayout='pt-0'
      showFooter={false}
      backgroudColor='bg-white'
    >
      {contextHolder}
      <div className='sapp-mw-1200px mx-auto px-2'>
        <div className='pb-0'>
          {newStep[0].status === 'current' && (
            <CreateAndUpdateInfo
              useFormProp={EventForm}
              onSubmit={handleSubmit(onSubmit)}
              onCancel={handleCancel}
              loading={isLoading}
              step={newStep}
              setStep={setNewStep}
            />
          )}
          {newStep[1].status === 'current' && (
            <CreateAndUpdateContent
              useFormProp={EventForm}
              onNextStep={handleNextStep}
              onCancel={handleCancel}
              step={newStep}
              setStep={setNewStep}
            />
          )}
          {newStep[2].status === 'current' && (
            <CreateAndUpdateSetting
              useFilter={useFilter}
              useFormProp={EventForm}
              onSubmit={handleSubmit(onSubmit)}
              onCancel={handleCancel}
              loading={isLoading}
              step={newStep}
              setStep={setNewStep}
            />
          )}
        </div>
      </div>
    </PageLayouts>
  )
}

export default EventDetail
